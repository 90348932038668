import React,{ useState } from "react";
import backendApi from "../utils/BackendApi";
import {useTranslation} from "react-i18next";
import { useNavigate } from "react-router-dom";

const AgentResetPasswordComponent = ({ onHideForm }) => {

    const [t,i18n] = useTranslation("global");
    const navigate = useNavigate();
    const urlPrefix = ()=> '/'+t("lng")+'/';
    const pathToLogin = urlPrefix() + t("url.login");


    const [showResetPasswordFields, setShowResetPasswordFields] = useState(false);

    const [resetKey, setResetKey] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [matchingPassword, setMatchingPassword] = useState("");
    const [resetKeyError, setResetKeyError] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [matchingPasswordError, setMatchingPasswordError] = useState("");


    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleEmailChange = (event) => {
        const inputValue = event.target.value;
        setEmail(inputValue);

        if (!inputValue) {
          setEmailError('Field is required');
        } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(inputValue)) {
          setEmailError('Invalid email format');
        } else {
          setEmailError('');
        }
    };


   const handleGetResetKeyButton = async () => {
       if (emailError) {
           alert('Please fix the errors or fill the unfilled field before resetting.');
           return;
       }
       try {

           const response = await backendApi.get("/resetPassword/" + email);
           console.log( "Got response from server: ");

           if( response.data.message){
                alert( response.data.message);
           }


           setShowResetPasswordFields(true);

       } catch (error) {
           // Handle error here, e.g., show an error message to the user
           console.error('Error while resetting password:', error);
           alert(error.response.data.message);

       }
   };



    const handleResetKeyChange = (event) => {
        const inputValue = event.target.value;
        setResetKey(inputValue);

        if (!inputValue) {
            setResetKeyError("Field is required");
        } else {
            setResetKeyError("");
        }
    };

    const handleNewPasswordChange = (event) => {
        const inputValue = event.target.value;
        setNewPassword(inputValue);

        if (!inputValue) {
            setNewPasswordError("Field is required");
        } else {
            setNewPasswordError("");
        }
    };

    const handleMatchingPasswordChange = (event) => {
        const inputValue = event.target.value;
        setMatchingPassword(inputValue);

        if (newPassword !== inputValue) {
            setMatchingPasswordError("Passwords do not match");
        } else {
            setMatchingPasswordError("");
        }
    };

    const handleResetPasswordButton = async () => {
        if( resetKeyError || newPasswordError || !(newPassword === matchingPassword) ) {
             alert('Please fix the errors or fill the unfilled field before resetting.');
            return;
        }

       try {
               const data = {
                   token: resetKey,
                   password: newPassword,
                   matchingPassword: matchingPassword,
               };

               const response = await backendApi.post("/resetPassword", data); // Add await here
               console.log("Got response from server: ", response.data);

               if (response.data.message) {
                   alert(response.data.message);
                   navigate(pathToLogin );

               }
           } catch (error) {
               console.error('Error while resetting password:', error);

               if (error.response && error.response.data && error.response.data.message) {
                   alert(error.response.data.message);
               } else {
                   alert('An error occurred while resetting the password.');
               }
           }

    };

    return (
        <main class="page-login">
        { !showResetPasswordFields ? (
            <div class="container-form">
                <h3 class="form_title orange">
                    <b> Email to which sent the Reset Keys </b>
                </h3>
                <div className="inner-form">
                    <div className="form-group field-name">
                        <label htmlFor="email">Email </label>
                        <input type="text" name="email" id="email" required="required" aria-required="true" value={email} onChange={handleEmailChange} />
                        {emailError && <div className="error-message">{emailError}</div>}
                    </div>
                </div>
                <button onClick={handleGetResetKeyButton}>Send Reset Key</button>
            </div>
        ) : (
                <div className="container-form">
                    <div className="inner-form">
                        <div className="form-group field-name">
                            <label htmlFor="resetKey">Reset Key </label>
                            <input
                                type="text"
                                name="resetKey"
                                id="resetKey"
                                required="required"
                                aria-required="true"
                                value={resetKey}
                                onChange={handleResetKeyChange}
                            />
                            {resetKeyError && <div className="error-message">{resetKeyError}</div>}
                        </div>
                    </div>
                    <div className="inner-form">
                        <div className="form-group field-name">
                            <label htmlFor="newPassword">New Password </label>
                            <input
                                type="password"
                                name="newPassword"
                                id="newPassword"
                                required="required"
                                aria-required="true"
                                value={newPassword}
                                onChange={handleNewPasswordChange}
                            />
                            {newPasswordError && <div className="error-message">{newPasswordError}</div>}
                        </div>
                    </div>
                    <div className="inner-form">
                        <div className="form-group field-matching-password">
                            <label htmlFor="matchingPassword">Confirm Password </label>
                            <input
                                type="password"
                                name="matchingPassword"
                                id="matchingPassword"
                                required="required"
                                aria-required="true"
                                value={matchingPassword}
                                onChange={handleMatchingPasswordChange}
                            />
                            {matchingPasswordError && <div className="error-message">{matchingPasswordError}</div>}
                        </div>
                    </div>
                    <button onClick={handleResetPasswordButton}>Reset Password</button>
                </div>
            )}
        </main>
    );
};

export default AgentResetPasswordComponent;


