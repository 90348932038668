import React, { useCallback,useState } from 'react';
import { useDropzone } from 'react-dropzone';


function FileDropZone({ onDrop }) {

   const [droppedFiles, setDroppedFiles] = useState([]);

   const handleDrop = useCallback((acceptedFiles) => {
     setDroppedFiles([...droppedFiles, ...acceptedFiles]);
     onDrop([...droppedFiles, ...acceptedFiles]); // Pass dropped files to parent component
     setDroppedFiles([]);
   }, [droppedFiles, onDrop]);

   const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });

   return (
     <div {...getRootProps()} style={dropzoneStyles} className='dropzone'>
       <input {...getInputProps()} />
       <p className='p-small'>Trascina qui i file oppure clicca per selezionare</p>
     </div>
   );
 }

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '16px',
  padding: '40px 20px',
  textAlign: 'center',
  cursor: 'pointer',
  display: 'block',
  margin: '1.2rem 0px',
};

const listItemStyles = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#f0f0f0',
  padding: '8px',
  margin: '8px 0',
};

const iconStyles = {
  width: '20px', // Adjust the size as needed
  height: '20px', // Adjust the size as needed
  marginRight: '8px',
};

export default FileDropZone;