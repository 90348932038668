import { useState } from "react";
import {  Route,Navigate  } from "react-router-dom";
import PrivateRoute from "../js/utils/PrivateRoute";
import {useTranslation } from "react-i18next";

import AgentHome from "../js/pages/private/agent/AgentHome"
import AgentClientsPage from "../js/pages/private/agent/AgentClientsPage"
import AgentNegotiationsPage from "../js/pages/private/agent/AgentNegotiationsPage"
import AgentNegotiationDetailsPage from "../js/pages/private/agent/AgentNegotiationDetailsPage"
import AgentInApprovalsPage from "../js/pages/private/agent/AgentInApprovalsPage"
import AgentOrdersPage from "../js/pages/private/agent/AgentOrdersPage"
import AgentCadPage from "../js/pages/private/agent/AgentCadPage"
import { useAgentLoginFormContext } from '../js/utils/AgentLoginFormContext';
import backendApi from "../js/utils/BackendApi";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Website Agent Pages
const AgentPaths = () => {

    const [t,i18n] = useTranslation("global")
    const { showAgentLoginForm } = useAgentLoginFormContext();
    {/* <Route index element={ <AgentHome /> } /> */}

    const notifySuccess = (message) =>  toast.success(message, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

      const notifyError = (message) =>  toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

    const fetchQuotation = async (token, maxAttempts = 100, currentAttempt = 1) => {
        try {
            console.log("Faccio richiesta al server con token:" + token);
            console.log("attempt: " + currentAttempt);
            const resp = await backendApi.get("api/quotation?token=" + token, { responseType: 'arraybuffer' });
            console.log(resp.data);
            console.log("len" + resp.data.byteLength);
            if (resp.data.byteLength > 0) {
                downloadFile(resp.data, 'quotation.xlsx');
                notifySuccess("Download completato");
                return; // Stop further attempts on successful download
            } else if (currentAttempt < maxAttempts) {
                await new Promise(resolve => setTimeout(resolve, 2000));
                fetchQuotation(token, maxAttempts, currentAttempt + 1);
            } else {
                notifyError("Download failed. Try again");
            }
        } catch (error) {
            notifyError("Download failed: "+error);
        }
    };

    const downloadFile = (data, fileName) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    return(
        <>
        <Route index element={<PrivateRoute onShowForm={ showAgentLoginForm } > <AgentClientsPage /> </PrivateRoute>} />
        <Route path={t("agentUrl.clients") } element={<PrivateRoute onShowForm={ showAgentLoginForm } >  <AgentClientsPage /> </PrivateRoute> } />
        {/*<Route path={t("agentUrl.negotiations") } element={<PrivateRoute onShowForm={ showAgentLoginForm } > <AgentNegotiationsPage makeDownloadRequestsForQuotation={ fetchQuotation } /> </PrivateRoute> } /> */}
        <Route path={t("agentUrl.negotiations") } element={<PrivateRoute onShowForm={ showAgentLoginForm } > <AgentNegotiationsPage /> </PrivateRoute> } />
        {/*Subpaths of negotiations url*/}
        <Route path={t("agentUrl.negotiations")+"/*" } element={<PrivateRoute onShowForm={ showAgentLoginForm } >  <AgentNegotiationDetailsPage /> </PrivateRoute> } />
        <Route path={t("agentUrl.negotiations")+"/**" } element={<PrivateRoute onShowForm={ showAgentLoginForm } >  <AgentNegotiationDetailsPage /> </PrivateRoute> } />
        {/*end of Subpath of negotiations url*/}
        <Route path={t("agentUrl.approvals") } element={<PrivateRoute onShowForm={ showAgentLoginForm } >  <AgentInApprovalsPage /> </PrivateRoute> } />
        <Route path={t("agentUrl.orders") } element={<PrivateRoute onShowForm={ showAgentLoginForm } > <AgentOrdersPage /> </PrivateRoute> } />
        <Route path={t("agentUrl.cad") } element={<PrivateRoute onShowForm={ showAgentLoginForm } > <AgentCadPage /> </PrivateRoute> } />
       </>
    )
}

export default AgentPaths;