import React, { useState,useEffect } from 'react';
import { Link, useNavigate,useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import { useAuth } from '../utils/AuthProvider';
import EmailConfirmationComponent from "./EmailConfirmationComponent";
import iconLoad from "../../img/form-loading.gif";
import iconClose from "../../img/icon_close.svg";
import iconPartner from "../../img/icon_partner.svg";
import iconMailOutline from "../../img/icon_mail.svg";
import iconPasswordOutline from "../../img/icon_password.svg";
import backendApi from "../utils/BackendApi.js"

const AgencyAgentAddClientComponent = ({ onHideForm }) => {

    const [t,i18n] = useTranslation("global");
    const urlPrefix = () => '/' + t('lng') + '/';
    const navigate = useNavigate();

    //variabili del form
    const [companyName, setCompanyName] = useState('');
    const [companyNameError, setCompanyNameError] = useState('');

    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState('');

    const [vat, setVat] = useState('');
    const [vatError, setVatError] = useState('');

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const [responseError, setResponseError] = useState('');


    //Handlers of form variables
    const handleInputChange = (event, setField, setError, regex) => {
        setResponseError("");
        const inputValue = event.target.value;
        setField(inputValue);

        if (!inputValue) {
            setError(t("agentAddClientComponent.fieldError.required"));
        }else if (!regex.test(inputValue)) {
            setError(t("agentAddClientComponent.fieldError.invalid"));
        } else {
            setError('');
        }
    };

    const handleCompanyNameChange = (event) => {
        handleInputChange(event, setCompanyName, setCompanyNameError, /^[A-Za-z0-9. ]+$/);
    };
    const handleLastNameChange = (event) => {
        handleInputChange(event, setLastName, setLastNameError, /^[A-Za-z0-9. ]+$/);
    };

    const handleVatChange = (event) => {
        handleInputChange(event, setVat, setVatError, /^[A-Za-z0-9]+$/);
    };

    const handleEmailChange = (event) => {
        handleInputChange(event, setEmail, setEmailError, /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/);
    };

    const handlePhoneChange = (event) => {
        handleInputChange(event, setPhone, setPhoneError, /^[0-9]{0,13}$/);
    };

    const loadingSubmit = document.getElementById('loading-submit');
    const buttonSubmit = document.getElementById('submit');

    //Loading
    const setShowLoadingOnForm = (display=true) => {
      if(display){
        loadingSubmit.style.display = 'block';
        buttonSubmit.disabled = true;
      }else{
        loadingSubmit.style.display = 'none';
        buttonSubmit.disabled = false;
      }
    }
    const sendData = async (data) => {
      setResponseError('');
      setShowLoadingOnForm();

      try {
        const resp = await backendApi.post("api/client", data);
//        alert( JSON.stringify(resp));
        if(resp.status ==200){
          setShowLoadingOnForm(false);
          onHideForm();
        }else{
           setResponseError(t("agentAddClientComponent.fieldError.40X"));
        }

      } catch (error) {
        //alert( JSON.stringify(error));
        setShowLoadingOnForm(false);
        if (error.response && error.response.data && error.response.data.message) {
          setResponseError(error.response.data.message);
        } else {
          setResponseError("An error occurred while submitting the form.");
        }
      }
    };


    const handleSubmit = async (event) => {
      event.preventDefault();

      if (companyNameError || lastNameError ||vatError || emailError || phoneError) {
        setResponseError('Please fix the errors before submitting');
        return;
      }

      const data = {
        companyName: companyName,
        lastName: lastName,
        vat: vat,
        email: email,
        phone: phone
      };

      sendData(data);

    };

    return (
        <main class="page-login px-4 py-3">
            <div class="container-form">
                 <img className='icon-close' src={iconClose} onClick={  onHideForm } width="32" height="32"/>
                 <h4 class="form_title">{t("agentAddClientComponent.title")}</h4>
                 <div className="inner-form">
                    <div className="form-group field-name">
                        <label htmlFor="companyName">{t("agentAddClientComponent.firstName")}</label>
                        <input type="text" name="companyName" id="companyName" required aria-required="true"
                        placeholder="Es. Mario or Furnish Srl"
                        value={companyName} onChange={handleCompanyNameChange} />
                        {companyNameError && <div className="error-message">{companyNameError}</div>}
                    </div>

                    <div className="form-group field-name">
                        <label htmlFor="lastName">{t("agentAddClientComponent.lastName")}</label>
                        <input type="text" name="lastName" id="lastName" required aria-required="true"
                        placeholder="Es. Rossi"
                        value={lastName} onChange={handleLastNameChange} />
                        {lastNameError && <div className="error-message">{lastNameError}</div>}
                    </div>

                     <div className="form-group field-email-client">
                        <label htmlFor="email-client">{t("agentAddClientComponent.email") }</label>
                        <input type="email" name="email" id="email" required aria-required="true"
                        placeholder="info@furnishsrl.com"
                        value={email} onChange={handleEmailChange} />
                        {emailError && <div className="error-message">{emailError}</div>}
                     </div>

                     <div className="form-group field-vat">
                        <label htmlFor="vat">{t("agentAddClientComponent.vat")}</label>
                        <input type="text" name="vat" id="vat" required aria-required="true"
                         placeholder="Es. 1234567891"
                        value={vat} onChange={handleVatChange} />
                        {vatError && <div className="error-message">{vatError}</div>}
                     </div>



                     <div className="form-group field-phone">
                        <label htmlFor="phone">{t("agentAddClientComponent.phone")}</label>
                        <input type="text" name="phone" id="phone" required aria-required="true"
                        pattern="^([0-9]{2,3} ){2}[0-9]{3} [0-9]{4}$" // Utilizza un pattern personalizzato
                        placeholder="Esempio: 39 366 446 5456"
                        value={phone} onChange={handlePhoneChange} />
                        {phoneError && <div className="error-message">{phoneError}</div>}
                     </div>
                 </div>

                 <button id="submit" disabled={false} className='btn orange' onClick={handleSubmit}> {t("agentAddClientComponent.submitButton")} </button>
                 <img style={{ display: 'none' }} id="loading-submit" src={iconLoad} width="40" height="40"/>
                 {responseError && <div className="notice error">{responseError}</div>}
           </div>
        </main>
    );
};

export default AgencyAgentAddClientComponent;
