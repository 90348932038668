import React from 'react';
import { Link } from 'react-router-dom';

const LargeSquareButton = ({ icon, text, onClick, linkTo, children, newTab }) => {
  if (linkTo) {
    if (newTab) {
      return (
        <a href={linkTo} target="_blank"  className="large-button">
          {icon && <span className="large-button_icon">{icon}</span>}
          {text && <span className="large-button_text">{text}</span>}
          {children} {/* Slot for custom markup */}
        </a>
      );
    } else {
      return (
        <Link to={linkTo}  className="large-button">
          {icon && <span className="large-button_icon">{icon}</span>}
          {text && <span className="large-button_text">{text}</span>}
          {children} {/* Slot for custom markup */}
        </Link>
      );
    }
  } else {
    return (
      <button  onClick={onClick} className="large-button">
        {icon && <span className="large-button_icon">{icon}</span>}
        {text && <span className="large-button_text">{text}</span>}
        {children} {/* Slot for custom markup */}
      </button>
    );
  }
}

export default LargeSquareButton;