import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/AuthProvider";
import backendApi from "../../../utils/BackendApi.js";
import iconSearch from "../../../../img/icon_search.svg";
import FormWindow from "../../../utils/FormWindow";
import AgentAddNegotiationComponent from "../../../components/AgentAddNegotiationComponent";
import { ToastContainer, toast } from 'react-toastify';
import iconAdd from "../../../../img/icon_add.svg";
//import AgentDesignComponent from "../../../components/AgentDesignComponent";

const AgentNegotiationsPage = () => {

     const [t, i18n] = useTranslation('global');
     const urlPrefix = ()=> '/'+t("lng")+'/';
     const navigate = useNavigate();
     const location = useLocation();
     //const pathToCadPage = "https://design.domino-design.it/project/";
     const { logout } = useAuth();

     const [data, setData] = useState([]); // Stato per i dati dei clienti
     const [filteredData, setFilteredData] = useState([]); // Stato per i dati filtrati
     const [searchTerm, setSearchTerm] = useState(""); // Stato per il termine su cui fare la ricerca

     const [isNewNegotiationFormVisible, setIsNewNegotiationFormVisible] = useState(false);
     const [isDesignFormVisible, setIsDesignFormVisible] = useState(false);
     const [idNegotiationSelected, setIdNegotiationSelected ] = useState("");

     const notifyError = (message) =>  toast.error(message, {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
      });

      useEffect(() => {
          const fetchInitData = async() => {
              try{
                  const response = await backendApi.get( "api/negotiation" );
                  const parsedData = JSON.parse(JSON.stringify(response.data) );
                  //format the date
                  parsedData.forEach(item => {
                      const date = new Date(item.last_update);
                      const day = date.getDate().toString().padStart(2, '0');
                      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                      const year = date.getFullYear();
                      const hours = date.getHours().toString().padStart(2, '0');
                      const minutes = date.getMinutes().toString().padStart(2, '0');
                      item.last_update = `${day}-${month}-${year} ${hours}:${minutes}`;
                  });

                  setData(parsedData);
                  setFilteredData(parsedData);

              }catch(error){
                  notifyError(t("agentNegotiationDetailsPage.errorMessage"));
              }
            }
            if( !isNewNegotiationFormVisible){ fetchInitData(); }
         }, [isNewNegotiationFormVisible]);

      useEffect(() => {
        // Filtra i dati ogni volta che cambia searchTerm
        const filteredResults = data.filter(
            (negotiation) => {
                const { id, client_id, last_update, title } = negotiation;
                const lowerCaseSearchTerm = searchTerm.toLowerCase();
                return (
                      id.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
                      client_id.toLowerCase().includes(lowerCaseSearchTerm) ||
                      last_update.toLowerCase().includes(lowerCaseSearchTerm) ||
                      title.toLowerCase().includes(lowerCaseSearchTerm)
                );
        });

        //Aggiorna filteredData con i risultati filtrati
        setFilteredData(filteredResults);
        console.log("My data are:");
        console.log( filteredData );
     }, [searchTerm]);

    //Form Functions
    const handleOnHideForm = () =>{
          setIsNewNegotiationFormVisible(false);
    }

     //Form Functions
//    const handleOnHideComponent = () =>{
//          setIsDesignFormVisible(false);
//    }
//    const handleDesignButton = (negotiationId)=>{
//        setIdNegotiationSelected( negotiationId );
//        setIsDesignFormVisible(true);
//    }

    const navigateToNegotiationDetailsPage=( negotiationId)=>{
        navigate( location.pathname+"/"+negotiationId );
    }


    //const handleDownloadQuotationFile = makeDownloadRequestsForQuotation;

    return (
      <div className="private-page">
        <h2><b>{t('agentNavBar.negotiations')}</b></h2>
        <div className='row-actions'>
          <div className='search-field'>
              <img src={iconSearch} width="24" height="24" xmlns="http://www.w3.org/2000/svg" />
              <input type="text" placeholder={t('agentNegotiationPage.searchBarPlaceholder')} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
          </div>
          <div>
            <button className='btn has-left-icon' onClick={ () => { setIsNewNegotiationFormVisible(true) } }><img src={iconAdd} width="20" height="20"/> {t('agentNegotiationPage.addNegotiationButton')}</button> 
          </div>
        </div>
        <table className='private-table has-row-link'>
          <thead>
            <tr >
              <th className='cell-id'>{t('agentNegotiationPage.id')}</th>
              <th >{t('agentNegotiationPage.title')}</th>
              <th >{t('agentNegotiationPage.client')}</th>
              <th >{t('agentNegotiationPage.lastUpdate')}</th>

              <th > </th>
            </tr>
          </thead>
          <tbody>
          {filteredData.map((negotiation, index) => (
              <tr key={index} onClick={ () => navigateToNegotiationDetailsPage(negotiation.id)  } >
                  <td className='cell-id'>{negotiation.id}</td>
                  <td >{negotiation.title}</td>
                  <td >{negotiation.client_id}</td>
                  <td >{negotiation.last_update}</td>

                  <td>
                    {/*<button onClick={ ()=> { handleDesignButton(negotiation.id) } }>{t("agentNegotiationPage.infoButton") }</button>*/}
                    {/* <Link to={ pathToCadPage+negotiation.cadId  } target="_blank" >{ t("agentNegotiationPage.cadButton") }</Link> */}
                    {/*<a href={ pathToCadPage+negotiation.cadId } target="_blank" >{ t("agentNegotiationPage.cadButton") }</a>*/}
                  </td>
              </tr>
          ))}
          </tbody>
          
        </table>
        { isNewNegotiationFormVisible && (<FormWindow> <AgentAddNegotiationComponent onHideForm={handleOnHideForm} /> </FormWindow>) }
        { /*isDesignFormVisible && (
            <FormWindow>
                <AgentDesignComponent
                onHideForm={handleOnHideComponent}
                negotiationId={idNegotiationSelected}
                downloadQuotationFile={ handleDownloadQuotationFile }
            /> </FormWindow>)*/}
      </div>
    );
};

export default AgentNegotiationsPage;

