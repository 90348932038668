
import React, {useState} from "react";
import axios from "axios";

const EmailConfirmationComponent = ({ onConfirmationResult }) => {

    const [token, setToken] = useState('');
      const handleTokenChange = (event) => {
          const inputValue = event.target.value;
          setToken(inputValue);
    };

    const handleSubmitEmailConfimationCode = async () => {

           try {
              const tokenData = {token};
              const response = await axios.post('http://localhost:8080/domino/registrationConfirmation', tokenData );
              console.log("Received response for verification token "+ JSON.stringify(response) );

              onConfirmationResult( response );

           } catch (error) {
              console.log(error);
              const errorMessage = error.response.data.message;
              const messageToPrint = errorMessage.split('\n')[0];
              alert( messageToPrint ) ;
           }

    };


    return(
        <div className="inner-form">
            <h2>Confirmation code</h2>
            <input type="password" name="confirmationCode" id="confirmationCode" required="required" aria-required="true" value={token} onChange={handleTokenChange} />
            <button onClick={handleSubmitEmailConfimationCode}>Submit Code</button>
        </div>
    );


}

export default EmailConfirmationComponent;