import { Link } from "react-router-dom";
import ParseHTML from "../../utils/parser";
import FormQuoteRequest from "../../components/FormQuoteRequest";
import IntroSinglePage from "../../components/IntroSinglePage";

function QuoteRequest() {

    const title = <ParseHTML id={ "quoterequest.pageTitle" } />

    return (
      <main class="page-contract"> 
         <IntroSinglePage title={ title } />
        <section class="px-4 entry-content">
          <div class="grid size_5-6 max-w-site">
            <div>
              <h2>
              <ParseHTML id={ "quoterequest.subTitle1" } />
              </h2>
              <p><ParseHTML id={ "quoterequest.text1" }/></p>
            </div>
            <div>
              <div class="container-form">
                <h3 class="form_title orange">
                    <b><ParseHTML id={ "quoterequest.formTitle" } /></b>
                </h3>
                <FormQuoteRequest />
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }

export default QuoteRequest;