import IFrameCadComponent from "../../../components/IFrameCadComponent";

const AgentCadPage = () => {

  return (
    <div className="private-page" style={{ width: "100%" }}>
        <IFrameCadComponent />
    </div>
  );
};

export default AgentCadPage;

