import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import "../../css/components/introSinglePage.css";

function IntroSinglePage( { title }) {

    const [t,i18n] = useTranslation("global");
    const LANG = t("lng");
   
    return (
        <section className="bg-gradient px-4 py-3 intro-single-page mb-8">
          <div className="max-w-site">
            <h1>{ title }</h1>
            <ol className="breadcrumbs">
              <li>
                <Link to={"/"+LANG} >{ t("breadcrumb.home") }</Link>
              </li>
              <li>
                {title}
              </li>
            </ol>
          </div>
        </section>
      
    );
  }

export default IntroSinglePage;