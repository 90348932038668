import ParseHTML from "../../utils/parser"
import IntroSinglePage from "../../components/IntroSinglePage";
import LoadImages from "../../utils/LoadImages";
import { Helmet } from 'react-helmet-async';
/*Helmet component allow to set a specific set of header properties of the page*/

function Company() {

  const title = <ParseHTML id={ "company.pageTitle" } />


    return (
    <>
        <Helmet>
            /*title dedicati per ogni pagina */
            <title>Company - Domino Design - Kitchens & Living Rooms</title>
            <meta name="description" content="Scopri i mobili personalizzati di Domino Design, leader nel settore dell'arredamento. Esplora il nostro sito web ufficiale e trova soluzioni di design uniche. Oppure vieni a visitare il nostro show room a Scordia, in provincia di Catania, Italia." />
            <meta name="description" content="Discover Domino Design's custom furniture, a leader in the furnishing industry. Explore our official website and find unique design solutions. Alternatively, visit our showroom in Scordia, located in the heart of the province of Catania, Italy." />
            <link rel="canonical" href={window.location.pathname} />
            /*Se non vuoi fare trovare questa pagina dai crawler scommenta questa riga*/
            /*<meta name="robots" content="noindex"/>*/
        </Helmet>

        <main class="page-company">
            <IntroSinglePage title={ title } />
            <section class="px-4 entry-content">
              <div class="grid size_6-6 max-w-site mb-5 align-center">
                <div>
                  <h2 class="h3"><b><ParseHTML id={ "company.row1Title" } /></b></h2>
                  <p><ParseHTML id={ "company.row1Text" } /></p>
                </div>
                <div>
                  <LoadImages src={"company-img1"} />
                </div>
              </div>
              <div class="grid size_6-6 max-w-site align-center">
                <div>
                  <LoadImages src={"company-img2"} />
                </div>
                <div class="pl-4">
                  <h2 class="h3"><b>
                  <ParseHTML id={ "company.row2Title" } /></b></h2>
                  <p><ParseHTML id={ "company.row2Text" } /></p>
                </div>
              </div>
            </section>
        </main>
    </>

    );
  }
  
  export default Company;
  