import React, { useState,useEffect } from 'react';
import { Link, useNavigate,useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useAuth} from '../utils/AuthProvider';
import EmailConfirmationComponent from "./EmailConfirmationComponent";
import GoogleLoginButton from "./GoogleLoginButton";

//import { Helmet } from 'react-helmet-async';

const UserLoginComponent = () => {

    //Check if the user is authenticated
    const { isAuthenticated, logout , login, setPathAfterSuccessfullyLogin, pathToDefaultDashboard} = useAuth();
    const [t,i18n] = useTranslation("global");
    const urlPrefix = () => '/' + t('lng') + '/';
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showCodeConfirmation, setShowCodeConfirmation] = useState(false);


    //const apikeyValue = "AIzaSyDTkrDufp8IN8fI3ykxXjMh7wboOBoIk-8";
    //const clientIdValue = '231326248811-a0udn3be72rivuuu0d2aujdihu8cm92l.apps.googleusercontent.com'


    const handleEmailChange = (event) => { setEmail(event.target.value); };
    const handlePasswordChange = (event) => { setPassword(event.target.value); };
    const handleLogin = async () => {
      const resp =  await login( email, password );
      if (resp.error){
          if( resp.error =="User is disabled"){
                alert("Before to login, please authenticate your account; Check you email for the code of activation!");
                setShowCodeConfirmation(true);
          }else{
            alert("Login Failed.\n"+resp.error);
            setShowCodeConfirmation(false);
          }

      }else{

        const patToRedirect = resp.success;
        alert("response was good, redirecting to "+patToRedirect);
        navigate( patToRedirect );
      }

  };
    const handleTokenResult = (response) => {
       console.log("Executing token result fun with response value=");
       console.log(response);
       if (response.status == 201){
           handleLogin();
       }
    };

    useEffect(() => {
      //alert("New User login component.\n\tisAutenticated = "+isAuthenticated);
      if( isAuthenticated ){
        const pathToDefaultDashboard = urlPrefix() + t("url.dashboard");
        alert("Redirectng to "+pathToDefaultDashboard);
        navigate( pathToDefaultDashboard );
      }

    }, []);



    return (
        <main class="page-login">
            <div class="container-form">
                {showCodeConfirmation ?
                ( <EmailConfirmationComponent onConfirmationResult={handleTokenResult} /> ) :
                ( <>
                 <h3 class="form_title orange">
                   <b> Login </b>
                 </h3>
                 <div className="signup-link">
                    Don't have an account? <Link to={ urlPrefix()+t("url.registration") } >Sign Up</Link>
                 </div>
                 <div className="inner-form">
                    <div className="form-group field-name">
                        <label htmlFor="email">Email </label>
                        <input type="text" name="email" id="email" required="required" aria-required="true" value={email} onChange={handleEmailChange} />
                    </div>
                 </div>
                 <div className="inner-form">
                    <div className="form-group field-name">
                    <label htmlFor="password">Password </label>
                    <input type="password" name="password" id="password" required="required" aria-required="true" value={password} onChange={handlePasswordChange} />
                 </div>
                 </div>
                 <div className="forgot-password">
                    <Link to={ urlPrefix()+t("url.resetpassword") } > Forgot Password ?</Link>
                 </div>
                 <button onClick={handleLogin}>Login</button>
                 <br></br>
                 <GoogleLoginButton />
                </>
               )}
           </div>
        </main>
    );
};

export default UserLoginComponent;
