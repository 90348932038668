import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter,Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import i18n from "./js/utils/i18n"
import { I18nextProvider } from "react-i18next";
import ScrollToTop from "./js/utils/ScrollToTop";
import ErrorBoundary from './js/errors_handler/ErrorBoundary';
import { AuthProvider } from './js/utils/AuthProvider';

//import css elements
import "./css/index.css";
import "./css/fix-singlepage.css";
import "./css/responsive.css";

import RoutingPrivatePaths from "./routing/RoutingPrivatePaths";
import RoutingPublicPaths from "./routing/RoutingPublicPaths";


export default function App() {

    return (
         <BrowserRouter>
             <ScrollToTop />
                <Routes basename={'/'}>
                    { RoutingPublicPaths() }
                    { RoutingPrivatePaths()  }
                </Routes>
         </BrowserRouter>
    );

}


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AuthProvider>
        <I18nextProvider i18n={i18n}>
          <ErrorBoundary >
            <HelmetProvider>
                <App />
            </HelmetProvider>
          </ErrorBoundary>
        </I18nextProvider>
    < /AuthProvider>
);

