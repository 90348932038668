import i18n from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
//import BrowserDetector from "i18next-browser-languagedetector";

import global_en from "../../langs/en/global.json"
import global_it from "../../langs/it/global.json"

const availableLangs = ['it', 'en'];

const pathDetector = {
  type: "languageDetector",
  detect: () => {
    const possibleLang = window.location.pathname.split("/")[1];
    const res = availableLangs.filter( l  => l==possibleLang  );
//    if (res){
//        return res;
//    }
//    //Check if the values is similar one of the eng urls
//    const urls = global_en.url
//    alert( urls );
//    res = Object.keys(urls).filter( (k) => urls[k]==possibleLang )
    return (res)? res : "it";

  },

  init: () => {},
  cacheUserLanguage: () => {}

};

//const languageDetector = new BrowserDetector();
//languageDetector.addDetector(PathDetector);

i18n
//  .use(BrowserDetector)
  .use(pathDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
      fallbackLng: "it",
      whitelist:availableLangs,
//      debug: true,
//      detection: {
//        order:['subdomain'],
//        checkWhitelist:true
//      },

      interpolation : {escapeValue : false},

      resources : {
        it : { global: global_it },
        en : { global: global_en }
      },
      react: { wait: true }
  });

export default i18n