import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/AuthProvider";
import backendApi from "../../../utils/BackendApi.js";

import iconChevronLeft from "../../../../img/icon_chevron-left.svg";
import loader from "../../../../img/form-loading.gif";
import LargeSquareButton from '../../../utils/LargeSquareButton.js';
import iconCad from "../../../../img/icon_cad.svg";
import iconExternalLink  from "../../../../img/icon_external-link.svg";
import iconInfo from "../../../../img/icon_info.svg";
import iconRefresh from "../../../../img/icon_refresh.svg";
import iconDocXls from "../../../../img/icon_doc-xls.svg";
import iconDocPdf from "../../../../img/icon_doc-pdf.svg";
import iconSend from "../../../../img/icon_send.svg";
import iconCheck from "../../../../img/icon_check.svg";

import { ToastContainer, toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip'

import FormWindow from "../../../utils/FormWindow";
import AgentDesignComponent from "../../../components/AgentDesignComponent";
import AgentSubmitDesignComponent from "../../../components/AgentSubmitDesignComponent";

const AgentNegotiationDetailsPage = () => {
    const [error, setError] = useState(null);
    const [t, i18n] = useTranslation('global');
    const urlPrefix = ()=> '/'+t("lng")+'/';
    const pathToCadPage = "https://design.domino-design.it/project/";
    const location = useLocation();
    const tokens = location.pathname.split("/");
    const negotiationId = tokens[tokens.length-1];

    const [titleNegotiation, setTitleNegotiation ] = useState("");
    const [negotiationStatus, setNegotiationStatus ] = useState("Creata");

    const [clientNegotiation, setClientNegotiation ] = useState("");
    const [idNegotiation, setIdNegotiation ] = useState("");
    const [idDesignToSubmit, setIdDesignToSubmit ] = useState("");
    const [designTitleToSubmit, setDesignTitleToSubmit ] = useState("");

    const [cadIdNegotiation, setCadIdNegotiation ] = useState("");
    const [designList, setDesignList] = useState([]);
    const designListRef = useRef(designList);

    const lastSubmittedIndexRef = useRef(-1);
    const [isSubmitFormVisible, setIsSubmitFormVisible] = useState(false);

    const navigate = useNavigate();
    const { logout } = useAuth();

    const navigateToNegotiationsPage=()=>{
        navigate( urlPrefix()  + "agent/" + t("agentUrl.negotiations") );
    }

    const notifyError = (message) =>  toast.error(message, {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
    });

    // Definizione della funzione sleep()
    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    const fetchDesignList = async() => {
        try {
            const response = await backendApi.get("api/design?negotiationId="+negotiationId);
            //format the date
            const data = response.data.map(( item,index) => {
            const date = new Date(item.lastUpdate);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            item.lastUpdate = `${day}-${month}-${year} ${hours}:${minutes}`;
            // Accedi al corrente stato di designListRef
            const currentDesignList = designListRef.current;
            const isUpdating = (currentDesignList[index] && currentDesignList[index].isUpdating) ? true : false;
            const isSubmitted = (currentDesignList[index] && currentDesignList[index].designSubmitted) ? true : false;
            return { ...item, isUpdating, isSubmitted };
            });
            console.log("nuova lista => "+JSON.stringify(data));
            setDesignList( data );
            designListRef.current = data;

        }catch (error) {
            console.log(error.response.data.message);
            //notifyError(t("agentNegotiationDetailsPage.errorMessage"));
        }

    }

    useEffect(() => {
        const fetchInitData = async() => {
            try {
                const response = await backendApi.get("api/negotiation_info?negotiationId="+negotiationId);
                const info = response.data;
                setTitleNegotiation( info.title );
                setClientNegotiation( info.client_id );
                setIdNegotiation( info.id );
//              alert(JSON.stringify(info));
                setNegotiationStatus(info.status);
                setCadIdNegotiation(info.cadId);

            } catch (error) {
                if( error.response.status==400 ){
                  //notifyError( error.response.data.message);
                  navigateToNegotiationsPage();
                }else{
                    notifyError(t("agentNegotiationDetailsPage.errorMessage"));
                }
            }

        }
        fetchInitData();
        fetchDesignList();
        const idInterval = setInterval(fetchDesignList, 30000);
        return () => {clearInterval(idInterval);}
    }, []);

    const updateDesignList = fetchDesignList;

    const handleOnHideForm = ( isTheFormSubmitted=false )=>{
        if(isTheFormSubmitted){
            const lastIdxClicked = lastSubmittedIndexRef.current;
            const currentDesignList = designListRef.current;
            currentDesignList[lastIdxClicked].designSubmitted = true;
        }
        setIsSubmitFormVisible(false);
    }

    const downloadFile = (data, fileName) => {

        const blob = new Blob([data], {
            type: fileName.endsWith("pdf")
                ? 'application/pdf'
                : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        console.log("Download del file completato: " + fileName);

    }


    const downloadQuotationFile = async(idNegotiation, idDesign, idx, fileType) => {

        if (designList[idx]) {
            designList[idx].isUpdating = true;
            setDesignList([...designList]);
            designListRef.current = designList;
//          console.log("Cambiato stato di designlist[idx].isUpdating => " + designList[idx].isUpdating);
        }

        const requestDto = {
            negId: parseInt(idNegotiation),
            desId: parseInt(idDesign),
            lang: t("lng"),
            returnType: fileType
        };

        while(true){
            // Ricevi il file
            const response = await backendApi.get("api/quotation", {
                     params: requestDto,
                     responseType: 'arraybuffer'
            });
            const file = response.data;
            if (file && file.byteLength > 0){

                if( fileType == "pdf" || fileType=="xlsx" ){
                    var fileName = 'quotation.'+fileType;
                    if (response.headers['filename']) {
                        fileName = response.headers['filename'];
                        console.log("Got filename = ");
                        console.log(fileName);
                    }
                    downloadFile(response.data, fileName);
                }

                if (designList[idx]) {
                        designList[idx].isUpdating = false;
                        designList[idx].downloadQuotationReady = true;
                        setDesignList([...designList]);
                        designListRef.current = designList;
                }
                return;
            }
            await sleep(5000); //tempo di sleep
        }
    }


    const handleSubmitButton = ( item, index ) => {
        setIdDesignToSubmit(item.id);
        setDesignTitleToSubmit(item.name);
        lastSubmittedIndexRef.current = index;
        setIsSubmitFormVisible(true);
    }

    return (
        <div className="private-page negotiation-details">
            <div className='row-actions'>
                <div>
                    <button className='go-back' onClick={()=> navigateToNegotiationsPage()} ><button className="icon-button-round"><img src={iconChevronLeft} /></button> {t('agentNegotiationDetailsPage.goBackNegotiations')}</button>
                    <h3 className='mb-2'><b>{titleNegotiation}</b></h3>
                    <table className='info-negotiation p-small'>
                        <tbody>
                            <tr>
                                <td>{t('agentNegotiationPage.id')}</td>
                                <td>{idNegotiation}</td>
                            </tr>
                            <tr>
                                <td>{t('agentNegotiationPage.client')}</td>
                                <td>{clientNegotiation}</td>
                            </tr>
                            <tr>
                                <td>{t('agentNegotiationPage.status')}</td>
                                <td>{negotiationStatus}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    {/*<LargeSquareButton
                        icon={<img src={iconCad} />}
                        text={t("agentNegotiationDetailsPage.newOrder")}
                        linkTo="#"
                        newTab={false}
                    ></LargeSquareButton>*/}
                    <LargeSquareButton
                        icon={<img src={iconCad} />}
                        text={ t("agentNegotiationDetailsPage.cadButton") }
                        linkTo={ pathToCadPage+cadIdNegotiation }
                        newTab={true}
                    ><div className='custom'><img src={iconExternalLink}/></div></LargeSquareButton>
                </div>
            </div>

            <div className='header-quotations'>
                <h4 className='m0'><b>{t("agentNegotiationDetailsPage.quotations")}</b></h4>
                <button className='toast_onpage small' onClick ={ () => updateDesignList() }><img src={iconInfo}/>{t("agentNegotiationDetailsPage.toastUpdateList")} <span>{t("agentNegotiationDetailsPage.toastUpdateListCta")}</span></button>
            </div>
            <table className='private-table'>
                <thead>
                    <tr >
                        <th className='cell-id'>{t('agentNegotiationDetailsPage.id')}</th>
                        <th >{t('agentNegotiationDetailsPage.title')}</th>
                        <th >{t('agentNegotiationDetailsPage.lastUpdate')}</th>
                        {/*<th >{t('agentNegotiationDetailsPage.statusDocument')}</th>*/}
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {
                    designList.map((item, index) => (
                    <tr key={index} className={ item.designSubmitted ?'submitted-row':''} >
                        <td className='cell-id'>{item.id}</td>
                        <td >{item.name}</td>
                        <td >{item.lastUpdate}</td>
                        {/*
                        <td >{item.downloadQuotationReady ? (
                            <p><span className='status-indicator doc-avaible'></span> {t('agentNegotiationDetailsPage.statusDocumentReady')}</p>
                        ):(
                            <p><span className='status-indicator doc-notavaible'></span> {t('agentNegotiationDetailsPage.statusDocumentNotReady')}</p>
                        )}</td>
                        */}
                        {item.isUpdating ?(
                               <>
                                <td className='table-cell-action quote'>
                                     <img width={24} height={24} src={loader} style={{marginRight: 18}}/>
                                </td>
                                <td className='table-cell-action action'>
                                    <button className="icon-button-round" data-tooltip-id="tooltipSubmit" data-tooltip-content="Conferma e invia" data-tooltip-delay-show={500} disabled > 
                                        <img width={28} height={28} src={iconSend} /> <Tooltip className="tooltip" id="tooltipSubmit" place="bottom"/>
                                    </button>
                                </td>
                               </>
                            ):(
                               <>
                                <td className='table-cell-action quote'>
                                    {item.downloadQuotationReady ? (
                                        <div>
                                            <button onClick={()=> downloadQuotationFile(idNegotiation, item.id, index, "xlsx")} className="icon-button-round"  data-tooltip-id="tooltipDownload" data-tooltip-content="Scarica xls" data-tooltip-delay-show={500}>
                                                <img width={28} height={28} src={iconDocXls} /> <Tooltip className="tooltip" id="tooltipDownload" place="bottom"/>
                                            </button>
                                            <button onClick={()=> downloadQuotationFile(idNegotiation, item.id, index,"pdf")} className="icon-button-round"  data-tooltip-id="tooltipDownload" data-tooltip-content="Scarica pdf" data-tooltip-delay-show={500}>
                                                <img width={28} height={28} src={iconDocPdf} /> <Tooltip className="tooltip" id="tooltipDownload" place="bottom"/>
                                            </button>
                                        </div>
                                    ):(
                                        <button onClick={()=> downloadQuotationFile(idNegotiation, item.id, index, "none" )} className="icon-button-round" data-tooltip-id="tooltipGenerate" data-tooltip-content="Genera preventivo" data-tooltip-delay-show={500}>
                                            <img width={28} height={28} src={iconRefresh} /> <Tooltip className="tooltip" id="tooltipGenerate" place="bottom"/>
                                        </button>
                                    )}
                                </td>
                                <td className='table-cell-action action'>
                                    {item.downloadQuotationReady ? (
                                        <div>
                                            <button className="icon-button-round submit-design" data-tooltip-id="tooltipSubmit" data-tooltip-content="Conferma e invia" data-tooltip-delay-show={500}
                                            onClick={()=> handleSubmitButton( item,index )  } >
                                            <img width={28} height={28} src={iconSend} /> <Tooltip className="tooltip" id="tooltipSubmit" place="bottom"/>
                                            </button>
                                            <button className='icon-button-round checked-submit-design'>
                                                <img width={28} height={28} src={iconCheck}/>
                                            </button>
                                        </div>
                                    ):(
                                        <button className="icon-button-round" disabled > 
                                            <img width={28} height={28} src={iconSend} /> 
                                        </button>
                                    )}
                                </td>
                               </>
                            )}

                    </tr>
                ))}
                
                </tbody>    
            </table>

            { isSubmitFormVisible && (<FormWindow> <AgentSubmitDesignComponent idNegotiation={idNegotiation} negotiationTitle={titleNegotiation} idDesign={idDesignToSubmit} designTitle={designTitleToSubmit}  onHideForm={ handleOnHideForm } /> </FormWindow>)}

            {/* isUpdateAccessoriesFormVisible && (<FormWindow> <AgentUpdateAccessoryComponent onHideForm={handleOnHideForm} /> </FormWindow>)}
            {/*{ isNewNegotiationFormVisible && (<FormWindow> <AgentAddNegotiationComponent onHideForm={handleOnHideForm} /> </FormWindow>)}
            { isDesignFormVisible && (
                <FormWindow>
                    <AgentDesignComponent
                    onHideForm={handleOnHideComponent}
                    negotiationId={idNegotiationSelected}
                    downloadQuotationFile={ handleDownloadQuotationFile }
                /> </FormWindow>)
            }*/}
        </div>
    );
}

export default AgentNegotiationDetailsPage;