import { Link, createRoutesFromChildren } from "react-router-dom";
import {useTranslation} from "react-i18next";
import "../../css/components/bannerContract.css";
import ParseHTML from "../utils/parser";
import BackgroundImage from "../utils/BackgroundLoadImages";


function BannerContract() {

    const [t,i18n] = useTranslation("global");
    const LANG = t("lng");
    const urlContatti = t("url.contacts");


    return (
        <section class="bannerContract mb-8">
          <div class="grid size_6-6 max-w-site mb-5 align-center">
            <div class="bg-gradient">
                <div class="innerBannerContract">
                    <p class="h2"><ParseHTML id={"bannerContract.title"}  /> </p>
                    <p><ParseHTML id={"bannerContract.text"} /></p>
                    <Link class="btn white" to={"/"+LANG+"/"+urlContatti} >{ t("bannerContract.button") }</Link>
                </div>
            </div>
            <div>
            <div class="img-bannerContract">
                <BackgroundImage src={"banner-contract-img"} />
            </div>

            </div>
          </div>
        </section>
    );
  }

export default BannerContract;