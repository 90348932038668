import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, Route,Outlet, Redirect} from 'react-router-dom';
import { useAuth } from "./AuthProvider";
import {useTranslation } from "react-i18next";


const PrivateRoute =({ children , onShowForm }) => {

  const { isAuthenticated , setPathAfterSuccessfullyLogin } = useAuth();
  const navigate=useNavigate();

  const [t, i18n] = useTranslation("global");
  const urlPrefix = ()=> '/'+t("lng")+'/';
  const loadAnyJwtToken = async () => await localStorage.getItem("token");

  useEffect(() => {
       if(!isAuthenticated ){
          setPathAfterSuccessfullyLogin(window.location.pathname);
          onShowForm();
//          navigate( urlPrefix()  ); //vai alla home pubblica con il login aperto
//          console.log("calling redirect");
//          //navigate( urlPrefix() );
//          console.log("called redirect");
       }
  }, []);

    return isAuthenticated ? children : null;


};

export default PrivateRoute;
