import ParseHTML from '../utils/parser'

function NoPageFound() {

  return (
    <div >
      <h1><ParseHTML id={"nopagefound.pageTitle"}  /></h1>
    </div>
  );

}

export default NoPageFound;
