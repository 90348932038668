import React, { useState,useEffect } from 'react';
import { Link, useNavigate,useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import { useAuth } from '../utils/AuthProvider';
import EmailConfirmationComponent from "./EmailConfirmationComponent";
import iconLoad from "../../img/form-loading.gif";
import iconClose from "../../img/icon_close.svg";
import iconPartner from "../../img/icon_partner.svg";
import iconMailOutline from "../../img/icon_mail.svg";
import iconPasswordOutline from "../../img/icon_password.svg";
import backendApi from "../utils/BackendApi.js"


const AgentAddNegotiationComponent = ({ onHideForm }) => {

    const [t,i18n] = useTranslation("global");
    const urlPrefix = () => '/' + t('lng') + '/';
    const navigate = useNavigate();

    //FORM VARIABLES
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState('');
    const [responseError, setResponseError] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState([]);

    const [customersList, setCustomersList] = useState([]);
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const [autocompleteFilteredOptions, setAutocompleteFilteredOptions] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [companyNameError, setCompanyNameError] = useState('');


    const getCustomers = async () => {
      try {
        const resp = await backendApi.get("api/client");
        if (resp.data && Array.isArray(resp.data)) {
//            const customerNames = resp.data.map((customer) => customer.companyName+" "+(customer.lastName==null?"":customer.lastName)+ " - "+customer.email);
            return resp.data;
        }
        return [];
      } catch (error) {
        console.error("Errore durante il recupero dei clienti:", error);
        return [];
      }
    };


    useEffect(() => {
       async function fetchData() {
         const receivedList = await getCustomers();
         setAutocompleteOptions(receivedList);
         setAutocompleteFilteredOptions(receivedList);
       }

       fetchData();
    }, []);

    const handleCompanyNameChange = (event) => {
      const input = event.target.value; //this is the typed string by user
      setCompanyName(input);
      setCompanyNameError(''); // Reimposta l'errore

      if (input) {
        const filteredOptions = autocompleteOptions.filter(
        (option) =>
          option.companyName && option.companyName.toLowerCase().includes(input.toLowerCase())
          ||
          option.lastName && option.lastName.toLowerCase().includes(input.toLowerCase())
        );
        setAutocompleteFilteredOptions(filteredOptions);
      } else {
        // Mostra tutti i clienti quando l'input è vuoto
        setAutocompleteFilteredOptions(autocompleteOptions);
      }
    };

      const handleAutocompleteOptionClick = (option) => {

        setSelectedCustomer(option);
        setCompanyName(option);
        setAutocompleteFilteredOptions([]);
      };

    const handleTitleChange = (event) => {
      handleInputChange(event, setTitle, setTitleError, /^[a-zA-Z0-9 .!@#$%^&*()_+=-]{1,50}$/);
    }

    const handleInputChange = (event, setField, setError, regex) => {
        const inputValue = event.target.value;
        setField(inputValue);

        if (!inputValue) {
            setError(t("agentAddNegotiationComponent.fieldError.required"));
        } else if (!regex.test(inputValue)) {
            setError(t("agentAddNegotiationComponent.fieldError.invalid"));
        } else {
            setError('');
        }
    };

    const loading = document.getElementById('loading-elem');
    const buttonSubmit = document.getElementById('submit');
    //Loading
    const setShowLoadingOnForm = (display=true) => {
      if(display){
        loading.style.display = 'block';
        buttonSubmit.disabled = true;
      }else{
        loading.style.display = 'none';
        buttonSubmit.disabled = false;
      }
    }

    const handleSubmit = async () => {
        setResponseError('');
        setShowLoadingOnForm();
//        const tokens = companyName.split("-");
        const data = {
            "clientName": selectedCustomer.companyName,
            "clientEmail": selectedCustomer.email,
            "title": title
        }

        try{
            const resp = await backendApi.post("api/negotiation", data);
            setShowLoadingOnForm(false);
            onHideForm();
        }catch(error){
           setShowLoadingOnForm(false);
           setResponseError("Impossibile creare una nuova trattativa");
        }

    };



    return (
        <main class="page-login px-4 py-3">
            <div class="container-form">
                 <img className='icon-close' src={iconClose} onClick={  onHideForm } width="32" height="32"/>
                 <h4 class="form_title">{t("agentAddNegotiationComponent.title")}</h4>
                 <div className="inner-form">
                    <div className="form-group field-name">
                        <label htmlFor="companyName">{t("agentAddNegotiationComponent.client")}</label>
                        <input type="text" name="companyName" id="companyName" placeholder="Search for a customer"
                          required aria-required="true" placeholder={t("agentAddNegotiationComponent.placeholder.client")}
                          value={selectedCustomer.companyName && selectedCustomer.companyName+" "+(selectedCustomer.lastName==null?"":selectedCustomer.lastName)}
                           onChange={ handleCompanyNameChange } />
                          <ul className='panel-search-results' >
                            {autocompleteFilteredOptions.map((option, index) => (
                              <li key={index} onClick={() => handleAutocompleteOptionClick(option) }>
                                {option.companyName+" "+(option.lastName==null?"":option.lastName)}
                              </li>
                            ))}
                          </ul>
                        {companyNameError && <div className="error-message">{companyNameError}</div>}
                    </div>

                    <div className="form-group field-name">
                        <label htmlFor="vat">{t("agentAddNegotiationComponent.titleProject")}</label>
                        <input type="text" name="title" id="title" required aria-required="true"
                         placeholder={t("agentAddNegotiationComponent.placeholder.title")}
                        value={title} onChange={handleTitleChange} />
                        {titleError && <div className="error-message">{titleError}</div>}
                    </div>
                 </div>

                 <button id="submit"   disabled={false} className='btn orange' onClick={handleSubmit}> {t("agentAddNegotiationComponent.submitButton")} </button>
                 <img style={{ display: 'none' }}  id="loading-elem" src={iconLoad} width="40" height="40"/>
                 {responseError && <div className="notice error">{responseError}</div>}
           </div>
        </main>
    );
};

export default AgentAddNegotiationComponent;
