import {  Route  } from "react-router-dom";
import AgentLayout from "../js/layout/private/AgentLayout";
import AgentPaths from "./AgentPaths";

const RoutingPrivatePaths = () => {
  //languages paths router manager
  return(
      <>
        <Route path={"/agent"} element={<AgentLayout />} >
          { AgentPaths() }
        </Route>
        <Route path={"/it/agent"} element={<AgentLayout />} >
          { AgentPaths() }
        </Route>
        <Route path={"/en/agent"} element={<AgentLayout />} >
          { AgentPaths() }
        </Route>
      </>
  );
};

export default RoutingPrivatePaths;
