import { Routes, Route  } from "react-router-dom";
import PublicPaths from "./PublicPaths";
import AgentsPaths from "./AgentPaths";
import AgentLayout from "../js/layout/private/AgentLayout";
import Layout from "../js/layout/Layout";


const RoutingPublicPaths = () => {
  const currentUrl = window.location.pathname;
  const isLandingPage = currentUrl.toLowerCase().includes("landing");
  const getLayout = () => ( isLandingPage ? null: <Layout /> );


  //languages paths router
  return(
    <>
      <Route path={"/"} element={ getLayout() } >
        { PublicPaths() }
      </Route>
      <Route path={"/it"} element={getLayout()} >
        { PublicPaths() }
      </Route>
      <Route path={"/en"} element={getLayout()} >
        { PublicPaths() }
      </Route>
    </>
  );
};

export default RoutingPublicPaths;
