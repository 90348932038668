import React, { useEffect, useRef, useState } from "react";

const IFrameCadComponent = ({path }) => {

   const pathToCadPage = "https://design.infurnia.com/project/";

   return (
      <iframe
            src={pathToCadPage}
            title="Domino Cad"
            style={{ width: "100%", height: "100%" }}
          ></iframe>
    )

}

export default IFrameCadComponent;
