
const AgentOrdersPage = () => {

  return (
    <div className="private-page">
        <p>Hey Agent welcome to your Agent Orders Page of your personal area !</p>
    </div>
  );
};

export default AgentOrdersPage;

