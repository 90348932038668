import IntroSinglePage from "../../components/IntroSinglePage";
import BannerContract from "../../components/BannerContract";
import ParseHTML from "../../utils/parser";
import LoadImages from "../../utils/LoadImages";

function About() {

    const title = <ParseHTML id={ "about.pageTitle" } />

    return (
      <main class="page-about">
        <IntroSinglePage title={ title } />
        <section class="px-4 entry-content">
          <div class="grid size_6-6 max-w-site mb-8">
            <div>
              <LoadImages src={"about-img1"} />
            </div>
            <div>
              <h2><b><ParseHTML id={ "about.row1Title" } /></b></h2>
              <p><ParseHTML id={ "about.row1Text" } /></p>
            </div>
          </div>
          <div class="grid size_6-6 max-w-site mb-8">
            <div>
              <h2><b><ParseHTML id={ "about.row2Title" } /></b></h2>
              <p><ParseHTML id={ "about.row2Text" } /> </p>
            </div>
            <div>

              <LoadImages src={"about-img2"} />
            </div>
          </div>
          <div class="grid size_6-6 max-w-site mb-8">
            <div>
               <LoadImages src={"about-img3"} />
            </div>
            <div>
              <h2><b><ParseHTML id={ "about.row3Title" } /> </b></h2>
              <p><ParseHTML id={ "about.row3Text" } /> </p>
            </div>
          </div>
          <BannerContract />
        </section>
      </main>
    );
  }
  
  export default About;