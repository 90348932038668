import axios from "axios";
import {useState,useEffect} from "react"
import placeholderImg from "../../img/placeholder1000x700.gif" ;
import { LazyLoadImage } from "react-lazy-load-image-component";

const LoadImages = (props) => {

  const src = props.src

  const [imageUrl, setImageUrl] = useState( placeholderImg );

  useEffect( () => {
    const fetchImage = async () => {
        try {

            const response = await axios.get(
            "https://www.domino-design.it/domino/api/ui-img/image/"+src,
            { responseType: "blob" }
            );
            const url = URL.createObjectURL(response.data);
            setImageUrl(url);

          } catch (error) {
            console.log(error);
          }
        };

    fetchImage();

  }, [] );

  return( <LazyLoadImage src={imageUrl} placeholderSrc={placeholderImg} /> );
};

export default LoadImages ;