import React, { createContext, useContext, useState } from 'react';

const AgentLoginFormContext = createContext();

export const useAgentLoginFormContext = () => {
  return useContext(AgentLoginFormContext);
};

export const AgentLoginFormProvider = ({ children }) => {

  const [isAgentLoginFormVisible, setAgentLoginFormVisible] = useState(false);

  const showAgentLoginForm = () => {
    setAgentLoginFormVisible(true);
  };

  const hideAgentLoginForm = () => {
    setAgentLoginFormVisible(false);
  };

  const contextValue = {
    isAgentLoginFormVisible,
    showAgentLoginForm,
    hideAgentLoginForm,
  };

  return (
    <AgentLoginFormContext.Provider value={contextValue}>
      {children}
    </AgentLoginFormContext.Provider>
  );
};

