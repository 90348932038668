import React, { useState, useEffect } from 'react';
import backendApi from "../utils/BackendApi";

const AccountInfo = () => {

    const [firstname, setFirstname ] = useState("");
    const [lastname, setLastName ] = useState("");
    const [email, setEmail ] = useState("");
    const [phone, setPhone ] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [userSince, setUserSinceDate] = useState("");

   useEffect(() => {

      const fetchInitData = async() => {

        const email = localStorage.getItem('email');
        try {
            const response =  await backendApi.get( "user/"+email );
            console.log(response);

            setFirstname(response.data.firstname);
            setLastName(response.data.lastname);
            setEmail(response.data.email);
            setPhone(response.data.phone);
            setDateOfBirth(response.data.dateofbirth);
            setUserSinceDate(response.data.usersince);

        } catch (error) {
            console.error(error);
            console.error(JSON.stringify(error));

        if (error.response) {
            console.error("Response Status:", error.response.status);
            console.error("Response Data:", error.response.data);
        } else {
            console.error("Error Message:", error.message);
        }
        alert('Error fetching data ' + JSON.stringify(error));
        //handleError();
        }

      }

      fetchInitData();

   }, []);

  return (
      <div>
        <h2>Hey! Welcome to your personal area Domino Customer Personal Information page.</h2>
        <p>First Name: {firstname}</p>
        <p>Last Name: {lastname}</p>
        <p>Email: {email}</p>
        <p>Phone: {phone}</p>
        <p>Date of Birth: {dateOfBirth}</p>
        <p>User Since: {userSince}</p>
        {/* Add more display elements as needed */}
      </div>
  );
};



export default AccountInfo;
