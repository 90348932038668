import { useState, useEffect } from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';

function PrivacyPolicy() {
    const [t,i18n] = useTranslation("global");
    const [bodyText, setBodyText] = useState("Loading ...");

    async function fetchDocs() {
        try {
            const LANG = t("lng");
            const urlEN = "https://www.iubenda.com/api/privacy-policy/94183403/no-markup";
            const urlIT = "https://www.iubenda.com/api/privacy-policy/59643425/no-markup";
            const url =  LANG=="en" ? urlEN : urlIT;
            const response = await axios.get(url);

            console.log(response.data.content);
            const contentString = response.data.content;
            setBodyText( parse(contentString) );

        } catch (error) {
            console.error(error);
        }
    }


    useEffect( () => {
        setBodyText("\t...");
        fetchDocs();
    }, [i18n.language, t] );


    return (
        <main class="page-cookies">
            <div>{ bodyText }</div>
       </main> );

}

export default PrivacyPolicy;
