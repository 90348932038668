import { React, createContext, useContext,useState } from 'react';
//import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AgentLoginFormProvider } from './AgentLoginFormContext';
import backendApi from "./BackendApi";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [t, i18n] = useTranslation('global');
  const urlPrefix = () => '/' + t('lng') + '/';
  const pathToDefaultDashboard = urlPrefix() + t("url.dashboard");

  const [redirectPath, setRedirectPath] = useState(null); // State to store the redirect path
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  const setPathAfterSuccessfullyLogin = (path) => {
    setRedirectPath(path);
  };

  const login = async (email, password, agent=false ) => {
    try {
            const loginData = { email:email, password:password };
            console.log("called login with agent: "+agent);

            var apiUrl = "/login";
            var pathToDefaultRedirect = pathToDefaultDashboard;

            if(agent){
                apiUrl="/login/agent";
                pathToDefaultRedirect= urlPrefix() + t("agentUrl.home");
            }
            const response = await backendApi.post(apiUrl, loginData);

            const jwtTokenString = response.headers.authorization
            const jwtToken = jwtTokenString.substring(7);  // Rimuovi "Bearer " dalla stringa
            await localStorage.setItem('token', jwtToken);
            await localStorage.setItem('email', email);
            setIsAuthenticated(true);

            const pathToRedirect = redirectPath !== null ? redirectPath : pathToDefaultRedirect

            console.log("returning redirect path "+pathToRedirect );

            setRedirectPath(null);
            const resp = { success: pathToRedirect };
            return resp;

    } catch (error) {

        console.log("Login failed: "+JSON.stringify(error))

        var msg="";
        if (agent){
            if( error.response && error.response.status ){
                msg = error.response.status
            }
        }else{
            if( error.response && error.response.data && error.response.data.message ){
                const errorMessage = error.response.data.message;
                msg = errorMessage.split('\n')[0];
            }
        }

        const resp = { error: msg};
        return resp;

    }
  };

  
  const logout = async( username ) => {
    //const response = await axios.post('/api/logout', { username });
    await localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <AgentLoginFormProvider>
        <GoogleOAuthProvider clientId="231326248811-a0udn3be72rivuuu0d2aujdihu8cm92l.apps.googleusercontent.com">
            <AuthContext.Provider value={{ isAuthenticated, login, logout , setPathAfterSuccessfullyLogin, pathToDefaultDashboard }}>
              {children}
            </AuthContext.Provider>
        </GoogleOAuthProvider>
    </AgentLoginFormProvider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};