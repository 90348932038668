import IntroLandingPage from "../../components/IntroLandingPage";
import MailChimpForm from "../../components/MailChimpForm";
import LandingFooter from "../../layout/LandingFooter";
import ParseHTML from "../../utils/parser";
import { Helmet } from 'react-helmet-async';
import GMap from "../../components/GoogleMapsOfDomino"
import "../../../css/components/footer.css";

import logo from "../../../img/logo.svg";

const ShowroomSchedulePage = ( props ) => {
    const url = props.url;
    const h = props.height;
    const w = props.width;


    const styles = {
        entryContentP: {
            letterSpacing: '0.25em',
            lineHeight: '1.2',
            marginBottom: '1.1em',
            maxWidth: '60rem',
        },
        h2: {
            fontWeight: 600,
            lineHeight: '1.1',
            marginBottom: '.8em',
            marginTop: '1.5em',
            fontSize: '4.2rem', // Aggiunta la virgola qui
        },

        h1: {
            fontSize: '5rem',
            fontWeight: 600,
            lineHeight: 1.1,
            marginBottom: '.5em',
            textAlign: 'center',
        },
    };

return (
    <main className="page-job" style={{ background: '#000000' }}>
        <IntroLandingPage />
         <section className="max-w-site" style={{ display: 'flex', justifyContent: 'center', paddingBottom: '0.0', marginBottom:'0.0', marginTop:'0.0', paddingTop:'0.0'}} >
            <iframe src={url}
                title="Appuntamenti Showroom"
                sandbox="allow-scripts allow-top-navigation allow-same-origin allow-forms"
                style={{  height: h, width: h,overflow: 'hidden' ,border: 'none', lineHeight: '0.9cd', maxWidth: '1200px'}}
            />
           </section>
           < GMap className="max-w-site" style={{ display: 'flex', justifyContent: 'center' }} />
          <LandingFooter />
        </main>

    );

};

export default ShowroomSchedulePage;