import axios from 'axios';

const backendApi = axios.create({
//  baseURL: "http://localhost:8080/domino"
  baseURL: "https://www.domino-design.it/domino/"
});

const getJwtToken = async () => {
  const token = await localStorage.getItem("token");
  return token;
};

backendApi.interceptors.request.use(

  async (request) => {
    const token = await getJwtToken();
    if (token) {
      request.headers['Authorization'] = 'Bearer ' + token;
    }
    //console.log("Request to send "+JSON.stringify(request));
    return request;
  }
);

export { getJwtToken };
export default backendApi;


