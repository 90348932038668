import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import 'react-phone-number-input/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import PhoneInput from 'react-phone-number-input';

import DatePicker from 'react-datepicker';
import EmailConfirmationComponent from "./EmailConfirmationComponent";
import { getName, getCodes } from 'country-list';


const UserRegistrationComponent = () => {

  const [t, i18n] = useTranslation('global');
  const urlPrefix = () => '/' + t('lng') + '/';
  const navigate = useNavigate();
  const [showCodeConfirmation, setShowCodeConfirmation] = useState(false);

  //FORM Fields and handler methods
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const handleFirstNameChange = (event) => {
      const inputValue = event.target.value;
      setFirstName(inputValue);

      if (!inputValue) {
        setFirstNameError('Field is required');
      } else {
        setFirstNameError('');
      }
    };


  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const handleLastNameChange = (event) => {
    const inputValue = event.target.value;
    setLastName(inputValue);

    if (!inputValue) {
      setLastNameError('Field is required');
    } else {
      setLastNameError('');
    }

  };



  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);

    if (!inputValue) {
      setEmailError('Field is required');
    } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(inputValue)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };




  const [password, setPassword] = useState('');
  const handlePasswordChange = (event) => {
      const inputValue = event.target.value;
      setPassword(inputValue);
  };


  const [matchingPassword, setMatchingPassword] = useState('');
  const handleMatchingPasswordChange = (event) => {
    const inputValue = event.target.value;
    setMatchingPassword(inputValue);
  };


  const [birthday, setBirthday] = useState('');
  const [birthdayError, setBirthdayError] = useState('');

  // Calculate the minimum date (90 years from today)
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 90);

  const handleBirthdayChange = (date) => {
        setBirthday(date);
        if (!date) {
            setBirthdayError('Field is required');
        } else {
            setBirthdayError('');
        }
    };



  //const [nationalityError, setNationalityError] = useState('');
  const [nationality, setNationality] = useState('');
  const countryCodes = getCodes();
  const nationalities = countryCodes.map((countryCode) => ({
      value: countryCode,
      label: getName(countryCode),
  }));

  const handleNationalityChange = (event) => {
      const selectedOption = event.target.value;
      setNationality(selectedOption);
  };


  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const handlePhoneChange = (value) => {
    setPhone(value);
    if (!value) {
       setPhoneError('Field is required');
    } else if (!/^\+?[0-9]{11,15}$/.test(value)) {
        setPhoneError('Invalid phone number');
    } else {
       setPhoneError('');
    }

  };

  const handleSignUp = async () => {

    if (
      firstNameError ||
      lastNameError ||
      emailError ||
      birthdayError ||
      !nationality ||
      phoneError ||
      !password ||
      password !== matchingPassword

    ) {
      alert('Please fix the errors or fill the unfilled fields before signing up');
      return;
    }
    // Date manipulation
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = birthday.toLocaleDateString('en-US', options).replace(',', '');

    // Create the JSON object
    const userData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        birthday: formattedDate,
        nationality: nationality,
        phone: phone,
        password: password,
        matchingPassword: matchingPassword,
    };

    try {

        // Send the POST request using Axios
        const response = await axios.post('http://localhost:8080/domino/registration', userData);
        alert(response.data.message + "\nWe sent the code token to your email!");
        setShowCodeConfirmation(true);

    } catch (error) {
        console.log(error);
        const errorMessage = error.response.data.message;
        const messageToPrint = errorMessage.split('\n')[0];
        alert( messageToPrint );
    }


  };

  const handleConfirmationResult = (response) => {
       alert( response.data.message + "\nYou will be redirected to login !");
       navigate(  urlPrefix()+t("url.login") );
  };

  return (
    <main className="page-registration">
      <div className="container-form">
        {showCodeConfirmation ?(
           <EmailConfirmationComponent onConfirmationResult={ handleConfirmationResult }/>
        ):(
            <>
            <h3 className="form_title orange">
                <b>New Account</b>
            </h3>
            <div className="inner-form">
                <div className="form-group field-name">
                    <label htmlFor="firstName">First Name </label>
                    <input type="text" name="firstName" id="firstName" required="required" aria-required="true" value={firstName} onChange={handleFirstNameChange} />
                    {firstNameError && <div className="error-message">{firstNameError}</div>}
                </div>
            </div>
            <div className="inner-form">
                <div className="form-group field-name">
                    <label htmlFor="lastName">Last Name </label>
                    <input type="text" name="lastName" id="lastName" required="required" aria-required="true" value={lastName} onChange={handleLastNameChange} />
                    {lastNameError && <div className="error-message">{lastNameError}</div>}
                </div>
            </div>
            <div className="inner-form">
                <div className="form-group field-date">
                    <label htmlFor="birthday">Birthday </label>
                    <DatePicker
                        selected={birthday}
                        onChange={handleBirthdayChange}
                        dateFormat= "dd-MM-yyyy"// "yyyy-MM-dd"
                        maxDate={ new Date()} // You might want to set a max date to prevent future dates
                        minDate={minDate} // Minimum date is 90 years ago
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Select a birthday"
                    />
                    {birthdayError && <div className="error-message">{birthdayError}</div>}
                </div>
            </div>
            <div className="inner-form">
                <div className="form-group field-nationality">
                  <label htmlFor="nationality">Nationality</label>
                  <select id="nationality" value={nationality} onChange={handleNationalityChange} >
                    <option value="">Select a nationality</option>
                        {nationalities.map((option) => (
                            <option key={option.value} value={option.value}> {option.label} </option>
                         ))}

                  </select>
                </div>
            </div>

            <div className="inner-form">
                 <div className="form-group field-email">
                   <label htmlFor="email">Email </label>
                   <input type="email" name="email" id="email" required="required" aria-required="true" value={email} onChange={handleEmailChange} />
                   {emailError && <div className="error-message">{emailError}</div>}
                </div>
            </div>

            <div className="inner-form">
                <div className="form-group field-tel">
                    <label htmlFor="tel">Phone </label>
                    <PhoneInput id="tel" required="required" aria-required="true" value={phone} onChange={handlePhoneChange}/>
                    {phoneError && <div className="error-message">{phoneError}</div>}
                </div>
            </div>

            <div className="inner-form">
                <div className="form-group field-password">
                    <label htmlFor="password">Password </label>
                    <input type="password" name="password" id="password" required="required" aria-required="true" value={password} onChange={handlePasswordChange} />
                </div>
            </div>
            <div className="inner-form">
                <div className="form-group field-matching-password">
                    <label htmlFor="matchingPassword">Confirm Password </label>
                    <input type="password" name="matchingPassword" id="matchingPassword" required="required" aria-required="true" value={matchingPassword} onChange={handleMatchingPasswordChange} />
                    {password !== matchingPassword && ( <div className="error-message">Passwords do not match</div> )}
                </div>
            </div>
            <button onClick={handleSignUp}>Sign Up</button>
            </>
        )}
      </div>
    </main>
  );
};

export default UserRegistrationComponent;
