import parse from 'html-react-parser';
import {useTranslation} from "react-i18next";

const ParseHTML = (props) => {

  const [t,i18n] = useTranslation("global");
  const text =  t(''+props.id);

  return( parse(text) );

}

export default ParseHTML;






