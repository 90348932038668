import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/AuthProvider";
import backendApi, {getJwtToken} from "../../../utils/BackendApi.js";
import jwt_decode from 'jwt-decode';

import FormWindow from "../../../utils/FormWindow";
import iconSearch from "../../../../img/icon_search.svg";
import iconAdd from "../../../../img/icon_add.svg";
import AgentAddClientComponent from "../../../components/AgentAddClientComponent";
import AgencyAgentAddClientComponent from "../../../components/AgencyAgentAddClientComponent";

const AgentClientsPage = () => {
   const [t, i18n] = useTranslation('global');
   const navigate = useNavigate();
   const { logout } = useAuth();

   const location = useLocation();
   const urlPrefix = ()=> '/'+t("lng")+'/';
   const pathToHome = urlPrefix();

   const [role, setRole] = useState('');
   const getRole = async () => {
           const token = await getJwtToken();
           setRole( jwt_decode(token)["Type"].toString() );
   };
   useEffect(() => { getRole(); }, []);

   const [data, setData] = useState([]); // Stato per i dati dei clienti
   const [filteredData, setFilteredData] = useState([]); // Stato per i dati filtrati
   const [searchTerm, setSearchTerm] = useState(""); // Stato per il termine su cui fare la ricerca

   const [isNewClientFormVisible, setIsNewClientFormVisible] = useState(false);

   useEffect(() => {

          const fetchInitData = async() => {
            try {
                const response = await backendApi.get( "api/client" );

                const rawData = JSON.stringify( response.data );
                const parsedData = JSON.parse(rawData);
                setData(parsedData);
                setFilteredData(parsedData);
            } catch (error) {

                console.log("AXIOS Error");
                console.log(error);
                console.log(JSON.stringify(error));

                if (error.response) {

                    if (error.response.status == 403){
                        //logout e rendirizza alla stessa pagina
                        await logout();
                        navigate( window.location.pathname );
                    }
                    console.log("Response Status:", error.response.status);
                    console.log("Response Data:", error.response.data);
                } else {
                    console.log("Error Message:", error.message);
                }

            }

          }
          fetchInitData();
          getRole();
       }, [isNewClientFormVisible]);

    useEffect(() => {
      // Filtra i dati ogni volta che cambia searchTerm
      const filteredResults = data.filter((client) => {
      const { companyName, vat, email, phone } = client;
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return (
        companyName.toLowerCase().includes(lowerCaseSearchTerm) ||
        vat.toLowerCase().includes(lowerCaseSearchTerm) ||
        email.toLowerCase().includes(lowerCaseSearchTerm) ||
        phone.toLowerCase().includes(lowerCaseSearchTerm)
     );
   });

   // Aggiorna filteredData con i risultati filtrati
   setFilteredData(filteredResults);

   }, [searchTerm]);


  //Form Functions
  const handleOnHideForm = () =>{
        setIsNewClientFormVisible(false);
  }

  const getRoleRender = ( listOfRoles ) =>{ return listOfRoles.includes(role)}


  return (
    <div className="private-page">
      <h2><b>{t('agentNavBar.clients')}</b></h2>
      <div className='row-actions'>
        <div className='search-field'>
          <img src={iconSearch} width="24" height="24" xmlns="http://www.w3.org/2000/svg" />
          <input type="text" placeholder={t('agentClientsPage.searchBarPlaceholder')} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
        </div>
        <div>
            {role.length>0 && <button className='btn has-left-icon' onClick={ () => { setIsNewClientFormVisible(true) } }><img src={iconAdd} width="20" height="20"/> {t('agentClientsPage.addClientButton')}</button>}
        </div>
      </div>
      <table className='private-table'>
        <thead>
          <tr>
            <th>{t('agentClientsPage.companyName')}</th>
            <th>{t('agentClientsPage.email')}</th>
            <th>{t('agentClientsPage.phone')}</th>
            <th>{t('agentClientsPage.vat')}</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((client, index) => (
              <tr key={index}>
                  <td>{client.companyName+(client.lastName==null ?"":" "+client.lastName)}</td>
                  <td>{client.email}</td>
                  <td>{client.phone}</td>
                  <td>{client.vat}</td>
              </tr>
          ))}
        </tbody>

      </table>
      { isNewClientFormVisible && role==='Agent'  && (<FormWindow> <AgentAddClientComponent onHideForm={handleOnHideForm} /> </FormWindow>)}
      { isNewClientFormVisible && role === 'AgencyAgent' && (<FormWindow> <AgencyAgentAddClientComponent onHideForm={handleOnHideForm} /> </FormWindow>)}
    </div>
  );
};

export default AgentClientsPage;

