import React, { useRef, useEffect } from 'react';
import "../../css/components/form-window.css";

const FormWindow = ({ children }) => {

  return (
    <div className="form-window">
      {children}
    </div>
  );
}

export default FormWindow;