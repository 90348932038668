import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import iconLang from "../../../img/icon_lang.svg";
import logo from "../../../img/logo.svg";

function AgentTopBar() {
  const [t,i18n] = useTranslation("global");
  const urlPrefix = ()=> '/'+t("lng")+'/';
  const pathToPublicHome = urlPrefix();
  const navigate = useNavigate();
  const location = useLocation();


  /*
    The function take in input the current language and apply text transformation.
    Furthermore, each path of the before-transformation url is mapped into is keys.
    Once the transformation happened, they keys are used to translate also the url.
    */
    const handleChangeLanguage = (newlang : string )=> {

      if ( t("lng") == newlang )
          return

      //get the current path and remove the first "/" the split into his elements
      let path = location.pathname.replace(/^./, ""); // "ello World!"
      path = path.split("/");
      const obj =t("agentUrl",{ returnObjects: true })
      let urlKeys ={};

      path.forEach( (elem) => {
          //console.log("elem => "+elem);
          for (const key in obj) {
              //console.log( "obj[key]"+obj[key]+" elem "+elem)
              if (obj[key] === elem) {
                  urlKeys[elem]=key
              }
          }
      });

      i18n.changeLanguage(newlang)
      let newURL =""

      for (const value in urlKeys){
          newURL+="/"+t("agentUrl."+urlKeys[value]);
      }

      navigate( "/"+t("lng") +newURL )

     }

    return (
        <section className="private-topbar topbar">
            <div className='topbar__left'>
                <img src={logo} width="176" height="44" viewBox="0 0 176 44" fill="none" xmlns="http://www.w3.org/2000/svg" />
            </div>
            <div className='topbar__right'>
                <Link className="backtosite" to={pathToPublicHome} >&#8592;&nbsp;&nbsp;{t("agentTopBar.goBackButton") } </Link>
                <div className={"cont-lang" + ( t("lng")=="it"? " it-selected" : " en-selected") } >
                    <button onClick={() => { handleChangeLanguage("en")}}>EN</button>
                    <img src={iconLang} width="18" height="18" xmlns="http://www.w3.org/2000/svg" />
                    <button onClick={ () => { handleChangeLanguage("it")} }>IT</button>
                </div> 
            </div>
        </section>
    );
}

export default AgentTopBar;