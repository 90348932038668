import {useEffect } from "react";

import { Outlet,useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import AgentNavBar from "./AgentNavBar";
import AgentTopBar from "./AgentTopBar";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';


const AgentLayout = () => {

  const location = useLocation();
  const [t,i18n] = useTranslation("global");
  const urlPrefix = ()=> '/'+t("lng")+'/';


  useEffect(() => {
    //trying to remove cookies
    document.body.classList.add('no-iubenda');
    var bottomBanner = document.getElementsByClassName("iub__us-widget");
    if( bottomBanner[0]){
    bottomBanner[0].style.display = 'none';
    }
    var floatingBanner = document.getElementsByClassName("iubenda-tp-btn");
    if( floatingBanner[0]){
        floatingBanner[0].style.display = 'none !important';
    }
    //iubenda-cs-preferences-link
    }, []);

  const currentUrl = location.pathname;
  const pathToCadPage = urlPrefix()+ t("agentUrl.home") + '/' + t("agentUrl.cad");

  const isCadPageVisible = currentUrl==pathToCadPage;

  return (
    <>
    <section className='private-content'>
      <AgentTopBar />
      <ToastContainer />
      <div className='private-inner'>
        {!isCadPageVisible ? <AgentNavBar /> : <></>}
        <Outlet />
      </div>
    </section>
    </>
  )};

export default AgentLayout;
