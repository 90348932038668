import IntroSinglePage from "../../components/IntroSinglePage";
import ParseHTML from "../../utils/parser";
import FormJobRequest from "../../components/FormJobRequest";

import axios from 'axios';
import React,  {useState, useEffect} from 'react';


function JobOpportunities() {
    //    const LANG = t("lng");

    const title = <ParseHTML id={ "job.pageTitle"} />

    const [openJobList, setOpenJobList] = useState([]);

    useEffect(() => {
        axios.get("https://www.domino-design.it/domino/api/ui-form/utils/open-job-position-list")
          .then(response => {
            setOpenJobList( response.data.message.JobPosition );
          })
          .catch(error => {
              alert('Error fetching data:'+ JSON.stringify(error));
          });
      }, []);


    return (
      <main class="page-job">
        <IntroSinglePage title={ title } />
        <section class="px-4 entry-content">
          <div class="grid size_5-6 max-w-site">
            <div>
              <h2> <ParseHTML id={ "job.subTitle1"} />  </h2>
              <p> <ParseHTML id={ "job.text1"} />  </p>
              <div class="space mb-8"></div>
              <p><b><ParseHTML id={ "job.jobOpportunitiesTitle"} /> </b></p>
              <ul>
                {
                    openJobList.length > 0 ?
                    ( openJobList.map((jobName, index) => ( <li key={index}>{jobName}</li> )) ) :
                    ( <li><ParseHTML id={ "job.jobPositionEmpty"} /></li> )
                }

              </ul>
            </div>
            <div>
              <div class="container-form">
                <h3 class="form_title orange">
                    <b><ParseHTML id={ "job.formTitle"} /> </b>
                </h3>
                <FormJobRequest openPositions={openJobList}/>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }

export default JobOpportunities;

//
//<div class="inner-form">
//                    <div class="form-group field-name">
//                        <label for="name"><span class="required">*</span>Nome e Cognome</label>
//                        <input type="text" name="name" access="false" id="name" required="required" aria-required="true"/>
//                    </div>
//                    <div class="form-group field-email">
//                        <label for="email"><span class="required">*</span>Email</label>
//                        <input type="email" name="email" access="false" id="email" required="required" aria-required="true"/>
//                    </div>
//                    <div class="form-group field-tel">
//                        <label for="tel"><span class="required">*</span>Telefono</label>
//                        <input type="text" name="tel" access="false" id="tel" required="required" aria-required="true"/>
//                    </div>
//                    <div class="form-group field-mansione">
//                        <label for="mansione"><span class="required">*</span>Posizione per cui ti candidi</label>
//                        <select name="mansione" id="mansione" required="required" aria-required="true">
//                            <option value="option-1" selected="true" id="mansione-0">Option 1</option>
//                            <option value="option-2" id="mansione-1">Option 2</option>
//                            <option value="candidatura-spontanea" id="mansione-2">Candidatura spontanea</option>
//                        </select>
//                    </div>
//                    <div class="form-group field-curriculum">
//                        <label for="curriculum"><span class="required">*</span>Curriculum Vitae</label>
//                        <input type="file" placeholder="Carica File" name="curriculum" access="false" multiple="false" id="curriculum" required="required" aria-required="true"/>
//                    </div>
//                    <div class="form-group field-message">
//                        <label for="message"><span class="required">*</span>Parlaci di te</label>
//                        <textarea type="textarea" name="message" access="false" rows="10" id="message" required="required" aria-required="true"></textarea>
//                    </div>
//                    <div class="form-group field-submit">
//                        <button class="btn orange float-r" type="submit" name="submit" access="false" id="submit">Invia</button>
//                    </div>
//                </div>