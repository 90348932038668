//
//import React from 'react';
//import { MapContainer, TileLayer, Marker } from 'react-leaflet';
//import L from 'leaflet';
//import "leaflet/dist/leaflet.css";
//import icon from "../../img/marker.png";
//
//const GMapOLD = () => {
//
//  const center = { lat: 37.29159, lng: 14.85617};
//  const zoomLevel = 16;
//
//  const customIcon = L.icon({
//    iconUrl: icon,
//    iconSize: [48, 48]
//
//  });
//
//  return (
//    <div style={{ position: 'relative' , overflow: 'hidden', zIndex: '0'  }}>
//        <MapContainer center={center} zoom={zoomLevel} style={{ height: '500px', width: '100%' }}>
//          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
//          <Marker position={center} icon={customIcon}  />
//        </MapContainer>
//    </div>
//  );
//};

import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';


const GMap = () => {
  const center = { lat: 37.29159, lng: 14.85617};
  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyAkfvsB2t-PWemvhhAF4pKPUPB4jxqESHU"  >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8}
      >
        {/* Puoi aggiungere i marker qui */}
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};


export default GMap;