
const AgentInApprovalsPage = () => {

  return (
    <div className="private-page">
        <p>Hey Agent welcome to in Approvals Page personal area ! </p>
    </div>
  );
};

export default AgentInApprovalsPage;

