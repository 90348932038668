import { useState, useEffect } from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';

function InfoAiutiStato() {
    
    return (
         <main class="page-info-aiuti-stato">
            <div>
             <table>
                  <thead>
                    <tr>
                      <th colSpan={6} >
                        Aiuti di Stato e contributi ricevuti nell anno 2022 - Domino Design srl </th>
                    </tr>
                    <tr>
                      <th style={{ border: '1px solid black', padding: '5px' }}>Denominazione soggetto erogante</th>
                      <th style={{ border: '1px solid black', padding: '5px' }}>Codice fiscale soggetto erogante</th>
                      <th style={{ border: '1px solid black', padding: '5px' }}>Somma incassata</th>
                      <th style={{ border: '1px solid black', padding: '5px' }}>Data incasso/concessione</th>
                      <th style={{ border: '1px solid black', padding: '5px' }}>Causale</th>
                      <th style={{ border: '1px solid black', padding: '5px' }}>Aiuti presenti su RNA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '5px' }}>Banca Agricola Popolare di Ragusa</td>
                      <td style={{ border: '1px solid black', padding: '5px' }}>00026870881</td>
                      <td style={{ border: '1px solid black', padding: '5px' }}> </td>
                      <td style={{ border: '1px solid black', padding: '5px' }}>24/10/2022</td>
                      <td style={{ border: '1px solid black', padding: '5px' }}>SME Initiative Italia - Bando Pmi Sme Initiative 1</td>
                      <td style={{ border: '1px solid black', padding: '5px' }}>
                      “Obblighi informativi per le erogazioni pubbliche: gli aiuti di Stato e gli aiuti de minimis ricevuti dalla nostra
                      impresa sono contenuti nel Registro nazionale degli aiuti di Stato di cui all’art. 52 della L. 234/2012”
                      e consultabili al seguente <a href="https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/TrasparenzaAiuto.jspx"> link</a>, inserendo come chiave di ricerca nel campo <b>CODICE FISCALE</b> 05935800879</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '5px' }}>Banca del Mezzogiorno MedioCredito
                                                                                Centrale S.p.A.</td>
                      <td style={{ border: '1px solid black', padding: '5px' }}>0915101000</td>
                      <td style={{ border: '1px solid black', padding: '5px' }}></td>
                      <td style={{ border: '1px solid black', padding: '5px' }}>21/10/2022</td>
                      <td style={{ border: '1px solid black', padding: '5px' }}>Fondo di Garanzia lg.662/96 - Garanzia diretta</td>
                      <td style={{ border: '1px solid black', padding: '5px' }}>
                                            “Obblighi informativi per le erogazioni pubbliche: gli aiuti di Stato e gli aiuti de minimis ricevuti dalla nostra
                                            impresa sono contenuti nel Registro nazionale degli aiuti di Stato di cui all’art. 52 della L. 234/2012”
                                            e consultabili al seguente <a href="https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/TrasparenzaAiuto.jspx"> link</a>, inserendo come chiave di ricerca nel campo <b>CODICE FISCALE</b> 05935800879</td>
                    </tr>

                  </tbody>
                </table>

            </div>
       </main>
       );
}

export default InfoAiutiStato;


