import React, { useState, useEffect } from 'react';

const BackgroundImage = ( props ) => {

  const src = props.src;
  const children = props.children;

  const [backgroundUrl, setBackgroundUrl] = useState('');

  useEffect(() => {
    const fetchBackground = async () => {
      try {
        const response = await fetch('https://www.domino-design.it/domino/api/ui-img/image/'+src );
        if (response.ok) {
          const data = await response.blob();
          const imageUrl = URL.createObjectURL(data);
          setBackgroundUrl(imageUrl);
        } else {
          // Handle the error if the request fails
          console.error('Error:', response.status);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchBackground();
    // Clean up the URL object when the component is unmounted
    return () => URL.revokeObjectURL(backgroundUrl);
  }, []);

  return (
    <div style={{ backgroundImage: `url(${backgroundUrl})` }}>
     {children}
    </div>
  );
};

export default BackgroundImage;
