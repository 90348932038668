import { Outlet } from "react-router-dom";
import { useEffect } from "react";

import NavBar from "./NavBar";
import Footer from "./Footer";
import iconTel from "../../img/icon_tel.svg";

const Layout = () => {

  return (
    <>
    <NavBar />
    <Outlet />
    <Footer />
    <a class="shortcut-tel" href="tel:+39095 2880137"><img src={iconTel} width="30" height="30" xmlns="http://www.w3.org/2000/svg" /></a>
    </>
  )
};

export default Layout;
