import React, { useState, useEffect, useMemo} from 'react';
import { Link } from "react-router-dom";
import "../../css/components/navbar.css";
import logo from "../../img/logo.svg";
import {useTranslation} from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ToggleButton from "./HamburgerMenu";
import iconLang from "../../img/icon_lang.svg";
import iconLogin from "../../img/icon_login.svg";
import iconPartner from "../../img/icon_partner.svg";
import { useAuth } from "../utils/AuthProvider";
import backendApi from "../utils/BackendApi";
import FormWindow from "../utils/FormWindow";
import AgentLoginComponent from "../components/AgentLoginComponent"
import AgentResetPasswordComponent from "../components/AgentResetPasswordComponent"
import { useAgentLoginFormContext } from '../utils/AgentLoginFormContext';

function NavBar() {

  const [t,i18n] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const urlPrefix = ()=> '/'+t("lng")+'/';
  const pathToHome = urlPrefix();
//  const storedEmail = await localStorage.getItem("email");


  //const [cssStyle, setCssStyle] = useState( t("lng") )

  /*
  The function take in input the current language and apply text transformation.
  Furthermore, each path of the before-transformation url is mapped into is keys.
  Once the transformation happened, they keys are used to translate also the url.
  */
  const handleChangeLanguage = (newlang : string )=> {

    if ( t("lng") == newlang )
        return

    //get the current path and remove the first "/" the split into his elements
    let path = location.pathname.replace(/^./, ""); // "ello World!"
    path = path.split("/");
    const obj =t("url",{ returnObjects: true })
    let urlKeys ={};

    path.forEach( (elem) => {
        //console.log("elem => "+elem);
        for (const key in obj) {
            //console.log( "obj[key]"+obj[key]+" elem "+elem)
            if (obj[key] === elem) {
                urlKeys[elem]=key
            }
        }
    });

    i18n.changeLanguage(newlang)
    let newURL =""

    for (const value in urlKeys){
        newURL+="/"+t("url."+urlKeys[value]);
    }

    navigate( "/"+t("lng") +newURL )

   }

  // Check if page is home
  const [isHome, setIsHome] = useState(true);

  useEffect(() => {
    setIsHome( window.location.pathname === "/" || window.location.pathname === "/" + t("lng") || window.location.pathname === "/" + t("lng") + "/" );
  }, [ window.location.pathname ] );

  //Check if the user is authenticated
  const { isAuthenticated, logout } = useAuth();
  const handleLogout = async () => {
      await logout( );
      navigate( pathToHome );//navigate perform operation different from Link to. it also ensure that logout has finished. I need both.
  };

  // Fix Navbar on top after scroll
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 400);
    });
  }, [] );


  //show agent login window
  const { isAgentLoginFormVisible, showAgentLoginForm, hideAgentLoginForm } = useAgentLoginFormContext();
  const handleHideAgentLoginForm = () => {
      hideAgentLoginForm()
  };

  //show forgot password window
  const [isForgotPasswordAgentWindowVisible, setIsForgotPasswordAgentWindowVisible] = useState(false);
  const handleShowForgotPasswordForm = () => {
        hideAgentLoginForm();
        setIsForgotPasswordAgentWindowVisible(true);
  };
  const handleHideForgotPasswordForm = () => {
        setIsForgotPasswordAgentWindowVisible(false);
  };

  const [nameAgentTopBar, setNameAgentTopBar] = useState('');
//  const [storedEmailAgentTopBar, setStoredEmailAgentTopBar] = useState('');
  useEffect(() => {
      async function getAgentNameOrEmail() {
        try {
          const storedFullName = await localStorage.getItem('agentFullName');
          if (storedFullName) {
            setNameAgentTopBar(storedFullName);
          }else{
            const storedEmail = await localStorage.getItem('email');
            if (storedEmail) {
                setNameAgentTopBar(storedEmail);
            }else{
                setNameAgentTopBar(t("topbar.partners"));
            }
          }

        } catch (error) {
          console.error('Error retrieving email from local storage:', error);
        }
      }
      getAgentNameOrEmail();
  }, []); // Run this effect only once on component mount

  return (
    <>
    <div className='topbar px-4 p-xsmall'>
      <div className='topbar__left'>
      { isAuthenticated ?(
        <Link to={ pathToHome+t("agentUrl.home") } >
        <img src={iconPartner} width="18" height="18" xmlns="http://www.w3.org/2000/svg" />
        { nameAgentTopBar }
        </Link>
      ):(
       <Link className='account-link' onClick={() => { isAgentLoginFormVisible ? hideAgentLoginForm() :  showAgentLoginForm() } } >
        {t("topbar.partners") }
       </Link>
       )}
      </div>
      <div className='topbar__right'>
        <div className='topbar__right__cont'>
          <div className={"cont-lang" + ( t("lng")=="it"? " it-selected" : " en-selected") } >
            <button onClick={() => { handleChangeLanguage("en")}}>EN</button>
            <img src={iconLang} width="18" height="18" xmlns="http://www.w3.org/2000/svg" />
            <button onClick={ () => { handleChangeLanguage("it")} }>IT</button>
          </div>

         {/* USER Login/logout

         {isAuthenticated ? (
           <div className='cta-login'>
               <div className='account-dropdown'>
                 <span className='account-text'>{t("topbar.account")}</span>
                 <div className='account-dropdown-content'>
                   <Link to={urlPrefix() } className='account-link' onClick={handleLogout}>
                     {t("topbar.logout")}
                   </Link>
                   <br />
                   <Link to={urlPrefix() + t("url.accountinfo")} className='account-link'>
                     {t("topbar.accountinfo")}
                   </Link>
                 </div>
               </div>
             </div>
        ) : (
           <Link to={urlPrefix() + t("url.login")} className='cta-login'>
             <img src={iconLogin} width="20" height="20" alt="Login" />
             {t("topbar.login")}
           </Link>
         )}
          button*/}

        </div>
      </div>
    </div>
    <header className={`${isHome ? "is-home" : "" }  ${scroll ? "site-header px-4 shrink" : "site-header px-4 bg-gradient"}`}>
      <div className="cont-header py-2">
        <div className="site-logo">
          <Link to={"/"+t("lng") } >
            <img src={logo} width="176" height="44" viewBox="0 0 176 44" fill="none" xmlns="http://www.w3.org/2000/svg" />
          </Link>
        </div>
        <nav className="cont-nav">
          <ul className="inner-nav">
            <li className="item-nav">
              <Link to={ urlPrefix()+t("url.about") } > { t("navbar.about")}</Link>
            </li>
            <li className="item-nav">
              <Link to={ urlPrefix()+t("url.company") } >{ t("navbar.company")} </Link>
            </li>
            <li className="item-nav">
              <Link to={ urlPrefix()+t("url.job") } >{t("navbar.job")}</Link>
            </li>
            <li className="item-nav">
              <Link to={ urlPrefix()+t("url.contacts") } > { t("navbar.contacts") }</Link>
            </li>
            <li className="item-nav">
              <Link className={scroll ? "btn orange" : "btn white"} to={ urlPrefix()+t("url.quoterequest") } >{t("navbar.quoterequest")}</Link>
            </li>
          </ul>
        </nav>
        <div className="cont-hamburger">
          <ToggleButton />
        </div>
      </div>
    </header>

    {/* Replace with AgentLoginComponent if needed */}
    {isAgentLoginFormVisible && (
        <FormWindow>
            <AgentLoginComponent onHideForm={handleHideAgentLoginForm} onShowForgotPasswordComponent={handleShowForgotPasswordForm} />
        </FormWindow>
    )}
    {isForgotPasswordAgentWindowVisible && (
        <FormWindow>
            <AgentResetPasswordComponent onHideForm={handleHideForgotPasswordForm } />
        </FormWindow>
    )}

    </>
  );
}

export default NavBar;
