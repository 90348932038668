import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import "../../css/components/footer.css";
import logo from "../../img/logo.svg";
import iconPin from "../../img/icon_pin.svg";
import iconTel from "../../img/icon_tel.svg";
import iconMail from "../../img/icon_mail.svg";
import iconFb from "../../img/icon_fb.svg";
import iconIg from "../../img/icon_ig.svg";
import iconIn from "../../img/icon_in.svg";


import ParseHTML from "../utils/parser";

function LandingFooter() {
 const [t,i18n] = useTranslation("global");
 const LANG = t("lng")
 const urlPrefix = ()=> '/'+LANG+'/';
 const urlWidget = t("url.quoterequest");



 return (
    <footer className="landing-footer">
      <div className="px-4">
        <div className="max-w-site cont-footer">
          <div className="footer_col-3">
            <Link to={"/"+LANG }>
              <img className="footer_logo" src={logo} width="176" height="44" viewBox="0 0 176 44" fill="none" xmlns="http://www.w3.org/2000/svg" />
            </Link>
            <div className="footer_list-info">
              <h2 className="footer_label-info"><b>{ t("footer.where") }</b></h2>
              <ul>
                <li className="footer_label-text">
                  <span className="icon"><img src={iconPin} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                  <span className="text">Zona Industriale, Strada G <br></br> 95048 Scordia (CT)</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer_col-3">
            <div className="footer_list-info">
              <h2 className="footer_label-info"><b>{ t("footer.contact") }</b></h2>
              <ul>
                <li className="footer_label-text">
                  <span className="icon"><img src={iconTel} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                  <span className="text">095 2880137</span>
                </li>
                <li className="footer_label-text">
                  <span className="icon"><img src={iconMail} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                  <span className="text">amministrazione@domino-design.it</span>
                </li>
                <li className="footer_label-text">
                  <span className="icon"><img src={iconMail} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                  <span className="text">commerciale@domino-design.it</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer_col-3">
          <div className="footer_list-info">
            <h2 className="footer_label-info"><b>{ t("footer.social") }</b></h2>
            <ul className="footer-nav primary">
              <li className="footer_label-text">
                <span className="icon"><img src={iconIg} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                <span className="text"><a href="https://www.instagram.com/dominodesign_kitchens/" target="_blank" rel="noopener noreferrer"> Instagram</a></span>
              </li>
              <li className="footer_label-text">
               <span className="icon"><img src={iconIn} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                <span className="text"><a href="https://www.linkedin.com/company/domino-design-srl" target="_blank" rel="noopener noreferrer">LinkedIn</a></span>
              </li>
              <li className="footer_label-text">
                <span className="icon"><img src={iconFb} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                <span className="text"><a href="https://www.facebook.com/p/Domino-Design-100095634069593" target="_blank" rel="noopener noreferrer">Facebook</a></span>
              </li>
            </ul>
          </div>
        </div>

        </div>
      </div>
    </footer>
  );
}

export default LandingFooter;
