import { GoogleLogin, useGoogleLogin,  hasGrantedAllScopesGoogle ,useGoogleOneTapLogin } from "@react-oauth/google";
import '../../css/components/GoogleButton.css';
import jwt_decode from "jwt-decode";
import axios from "axios";

const GoogleLoginButton = () => {

    const handleGoogleFailure = (error) =>{
          console.log(error);
          alert(JSON.stringify(error));
      };

    const handleGoogleRegistrationSuccess = async (response) =>{
        console.log(response);
        const tokenResponse = response.access_token;
        const data = await axios.get( 'https://people.googleapis.com/v1/people/me?personFields=phoneNumbers',
            { headers: { Authorization: `Bearer ${tokenResponse}` } }
        );

        const phoneNumber = data.data.phoneNumbers[0].canonicalForm;
        alert("Ottenuto numero di telefono:"+ phoneNumber);
    };

    //https://www.googleapis.com/auth/contacts.readonly
    const userScopes = "openid profile email https://www.googleapis.com/auth/user.phonenumbers.read"

    const handleGoogleRegistration = useGoogleLogin({
          onSuccess: handleGoogleRegistrationSuccess,
          onFailure : handleGoogleFailure,
          flow: 'implicit',
          scope: userScopes,
    });

//    const handleGoogleLoginSuccess = async (response) => {
//        console.log("Eseguo handleGoogleLoginSuccess");
//        console.log(response);
//        const decoded = jwt_decode(response.credential);
//        console.log(decoded);
//        handleGoogleRegistration();
//    }
//
//      const automaticLogin = useGoogleOneTapLogin({
//        onSuccess: handleGoogleLoginSuccess,
//        onError: handleGoogleFailure,
//        scope : userScopes,
//        ux_mode:"popup"
//
//      });
//<GoogleLogin  onSuccess = {handleGoogleLoginSuccess} onFailure = {handleGoogleFailure} scope={userScopes} flow='implicit' useOneTap='true' auto_select='true' ux_mode="popup" />

  return (
    <button className="google-button" onClick={ handleGoogleRegistration }>
    <img width="16" height="16" src="https://img.icons8.com/color/16/google-logo.png"alt="Google Icon" className="google-icon"/>
      Sign in with Google
    </button>
  );
};

export default GoogleLoginButton;
