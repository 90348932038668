import React, { useState, useEffect } from 'react';
import backendApi from "../utils/BackendApi";

import {useAuth} from "../utils/AuthProvider";
import {  useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";


const DashboardHome = () => {
  const [firstname, setFirstname] = useState('');
  const { logout } = useAuth();

  const [t,i18n] = useTranslation("global");
  const urlPrefix = () => '/' + t('lng') + '/';
  const navigate = useNavigate();


  const handleError = () => {
        logout( );
        const pathToLogin = urlPrefix() + t("url.login");
        navigate( pathToLogin );
  }

  useEffect(() => {
      const fetchData = async () => {
        const email = localStorage.getItem('email');

        try {
         const response =  await backendApi.get( "user/"+email );
         console.log(response);
         setFirstname( response.data.firstname );
        } catch (error) {
          console.error(error);
          console.error(JSON.stringify(error));

          if (error.response) {
              console.error("Response Status:", error.response.status);
              console.error("Response Data:", error.response.data);
          } else {
              console.error("Error Message:", error.message);
          }

          alert('Error fetching data ' + JSON.stringify(error));
          //handleError();

        }
      };

      fetchData(); // Call the async function to fetch data
    }, []);



  return (
    <p>
      Hey {firstname ? `${firstname} ` : ''}, welcome to your personal area !
    </p>
  );
};

export default DashboardHome;

