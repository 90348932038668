import BannerContract from "../../components/BannerContract";
import HomeGallery from "../../components/HomeGallery";
import LoadImages from "../../utils/LoadImages";
import ParseHTML from "../../utils/parser";
import { Link, createRoutesFromChildren } from "react-router-dom";
import {useTranslation} from "react-i18next";

import BackgroundImage from "../../utils/BackgroundLoadImages";
import iconEcommercial from "../../../img/icon_ecommercial.svg";
import plus1 from "../../../img/icon_plus-progetto.svg";
import plus2 from "../../../img/icon_plus-consegna.svg";
import plus3 from "../../../img/icon_plus-assistenza.svg";
import plus4 from "../../../img/icon_plus-riciclo.svg";


function Home() {
  const [t,i18n] = useTranslation("global");
  const LANG = t("lng");
  const urlAbout = t("url.about");
  const urlCompany = t("url.company");
  const urlContract = t("url.quoterequest");

  return (
    <main className="page-home">
      <section className="above-fold">
        <BackgroundImage src ={"home-hero"}>
            <div className="cont-above-fold px-4">
                <div className="max-w-site t-align-center mb-5">
                  <h1 className="mb-2"><ParseHTML id={"home.pageTitle"} /></h1>
                  <p className="t-align-center mb-5"><ParseHTML id={"home.pageSubtitle"} /></p>
                  <Link className="btn white" to={"/"+LANG+"/"+urlContract} >{ t("footer.widgetContractButton") }</Link>
                </div>
             </div>
        </BackgroundImage>
      </section>
      <section className="px-4 bg-gradient py-7">
          <div className="grid size_5-6 max-w-site">
            <div className="border-t"><h2><ParseHTML id={"home.company-title"} /></h2></div>
            <div className="border-t">
              <p className="m0"><ParseHTML id={"home.company-text"} /></p>
              <div className="space mb-5"></div>
              <Link className="btn white float-r" to={"/"+LANG+"/"+urlCompany} >{ t("home.company-cta") }</Link>
            </div>
          </div>
      </section>
      <section className="image-divider mb-8">
          <div className="inner-image-divider">
          <BackgroundImage src={"home-divider-1"} />
          </div>
      </section>
      <section className="px-4 mb-8">
          <div className="grid size_5-6 max-w-site">
            <div className="border-t"><h2><ParseHTML id={"home.about-title"} /></h2></div>
            <div className="border-t">
              <p className="m0"><ParseHTML id={"home.about-text"} /></p>
              <div className="space mb-5"></div>
              <Link className="btn orange float-r" to={"/"+LANG+"/"+urlAbout} >{ t("home.about-cta") }</Link>
            </div>
          </div>
      </section>
      <div className="space mb-8"></div>
      <section className="px-4 mb-8 cucine-living">
          <img className="icon-e" src={iconEcommercial} width="120" height="120" xmlns="http://www.w3.org/2000/svg" />
          <div className="grid size_6-6 max-w-site">
            <div>
              <h3 className="orange"><b><ParseHTML id={"home.cucine"} /></b></h3>
              <p className="border-t mb-5 p-small"><ParseHTML id={"home.cucine-text"} /></p>
              <LoadImages src={"home-cucine"} />
            </div>
            <div>
              <h3 className="orange"><b><ParseHTML id={"home.living"} /></b></h3>
              <p className="border-t mb-5 p-small"><ParseHTML id={"home.living-text"} /></p>
               <LoadImages src={"home-living"} />
            </div>
          </div>
      </section>
      <section className="px-4 entry-content mb-8">
          <BannerContract />
      </section>
      <section className="image-divider">
          <div className="inner-image-divider">
          <BackgroundImage src={"home-divider-2"} />
          </div>
      </section>
      <section className="px-4 mb-8 bg-gradient">
          <div className="max-w-site t-align-center py-7">
            <h2 className="m0"><ParseHTML id={"home.reasonWhy1"} /> </h2>
            <h3 className="h2"><ParseHTML id={"home.reasonWhy2"} /> </h3>
            <div className="mb-5"></div>
            <p> <ParseHTML id={"home.reasonWhyText"} /> </p>
          </div>
      </section>
      <section className="px-4 entry-content mb-8">
          <div className="max-w-site grid size_3-3-3-3">
            <div>
              <p className="orange counter">01</p>
              <div className="cont-plus t-align-center"><img src={plus1} xmlns="http://www.w3.org/2000/svg" /></div>
              <h3 className="h4">
                <b><ParseHTML id={"home.plus1Title"} /> </b>
              </h3>
              <p className="p-small"><ParseHTML id={"home.plus1Text"} /></p>
            </div>
            <div>
              <p className="orange counter">02</p>
              <div className="cont-plus t-align-center"><img src={plus2} xmlns="http://www.w3.org/2000/svg" /></div>
              <h3 className="h4"><b><ParseHTML id={"home.plus2Title"} /></b></h3>
              <p className="p-small"><ParseHTML id={"home.plus2Text"} /></p>
            </div>
            <div>
              <p className="orange counter">03</p>
              <div className="cont-plus t-align-center"><img src={plus3} xmlns="http://www.w3.org/2000/svg" /></div>
              <h3 className="h4"><b><ParseHTML id={"home.plus3Title"} /></b></h3>
              <p className="p-small"><ParseHTML id={"home.plus3Text"}/></p>
            </div>
            <div>
              <p className="orange counter">04</p>
              <div className="cont-plus t-align-center"><img src={plus4} xmlns="http://www.w3.org/2000/svg" /></div>
              <h3 className="h4"><b><ParseHTML id={"home.plus4Title"} /></b></h3>
              <p className="p-small"><ParseHTML id={"home.plus4Text"} /></p>
            </div>
          </div>
      </section>
      <section className="image-divider">
          <div className="inner-image-divider">
            <BackgroundImage src={"home-divider-3"} />
          </div>
      </section>
      <section className="px-4 mb-8 entry-content photo-carousel">
        <div className="py-7">
          <div className="max-w-site t-align-center">
            <h2 className="m0 h1"><ParseHTML id={"home.carouselTitle"} /> </h2>
            <div className="mb-2"></div>
            <p> <ParseHTML id={"home.carouselText"} /> </p>
            <Link className="btn orange" to={"/"+LANG+"/"+urlContract} >{ t("footer.widgetContractButton") }</Link>
            <div className="space mb-7"></div>
          </div>
          <HomeGallery />
        </div>
      </section>
    </main>
  );

}

export default Home;
