import LoadImages from "../utils/LoadImages";
import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import SwiperCore,{ Pagination } from 'swiper';


import axios from "axios";

import 'swiper/swiper.min.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



SwiperCore.use([Pagination]);


const AutoSlidesPerView = () => {

  const [imageIdsList, setImageIdsList] = useState([]);

  useEffect(() => {
    const fetchImageIds = async () => {
      try {
        const response = await axios.get( "https://www.domino-design.it/domino/api/ui-img/image-ids/home-swiper");
        console.log("got response: ", response.data.message );
        setImageIdsList(response.data.message);
      } catch (error) {
        console.log("Error fetching image ids:", error);
      }
    };

    fetchImageIds();
  }, [] );


   const params = {
       slidesPerView: 'auto',
       spaceBetween: 20,
       grabCursor: true,
       loop: true,
       pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        dynamicBullets: true
      },
       autoplay: {
         delay: 2500,
         disableOnInteraction: false
       },
       breakpoints: {
         1024: {
           slidesPerView: 'auto',
           //slidesPerView: 2.5,
           spaceBetween: 20
         },
         768: {
          slidesPerView: 'auto',
           //slidesPerView: 1.75,
           spaceBetween: 18
         },
         300: {
          slidesPerView: 'auto',
           //slidesPerView: 1.1,
           spaceBetween: 10
         }
       }
     }

  return (
    <Swiper {...params} >
        { imageIdsList.map((id)=>(
             <div key={id}> <LoadImages src={id}/> </div>
           ))}
    </Swiper>
  );
};

export default AutoSlidesPerView;
  