import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import "../../css/components/introSinglePage.css";
import logo from "../../img/logo.svg";

function IntroLandingPage( { title }) {

    const [t,i18n] = useTranslation("global");
    const LANG = t("lng");
    const introStyles = {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',  // Changed from 'flex-direction'
      flexWrap: 'nowrap',       // Changed from 'flex-wrap'
      justifyContent: 'flex-end',
      minHeight: '0px',        // Removed 'Importan!'
      overflow: 'hidden',
      position: 'relative',     // Added a colon
    };



    return (
    <section className="bg-gradient px-4 py-3 intro-single-page landing-important" style={introStyles}>
      <div className="max-w-site" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' , paddingBottom:'0.0'}}>
        <img src={logo} style={{ width: '500px', height: 'auto' }} viewBox="0 0 176 44" fill="none" xmlns="http://www.w3.org/2000/svg" />
      </div>
    </section>
    );
  }

export default IntroLandingPage;