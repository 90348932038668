import {  Route  } from "react-router-dom";
import PrivateRoute from "../js/utils/PrivateRoute";
import {useTranslation } from "react-i18next";

//Website Public Pages
import Home from "../js/pages/public/Home";
import Company from "../js/pages/public/Company";
import About from "../js/pages/public/About";
import QuoteRequest from "../js/pages/public/QuoteRequest";
import JobOpportunities from "../js/pages/public/JobOpportunities";
import Contacts from "../js/pages/public/Contacts";
import UserLoginPage from "../js/pages/UserLoginPage";
import UserRegistrationPage from "../js/pages/public/UserRegistrationPage";
import ResetPasswordPage from "../js/pages/public/ResetPasswordPage";
import ShowroomSchedulePage from "../js/pages/public/ShowroomSchedulePage";

//Utility public pages
import NoPageFound from "../js/errors_handler/NoPageFound";
import TermsAndConditions from "../js/pages/public/TermsAndConditions";
import PrivacyPolicy from "../js/pages/public/PrivacyPolicy";
import CookiesPolicy from "../js/pages/public/CookiesPolicy";
import InfoAiutiStato from "../js/pages/public/InfoAiutiStato";

//Users private pages
import DashboardHome from "../js/pages/UserHome";
import AccountInfoPage from "../js/pages/UserInfoPage";

const PublicPaths = () => {

    const [t,i18n] = useTranslation("global")

    return(
    <>
    <Route index element={<Home />} />
    <Route path={ t("url.about")} element={<About />} />
    <Route path={ t("url.company")} element={<Company />} />
    <Route path={ t("url.quoterequest")} element={ <QuoteRequest />} />
    <Route path={ t("url.job")} element={<JobOpportunities />} />
    <Route path={ t("url.contacts")} element={<Contacts />} />
    <Route path={ t("url.privacy")} element={<PrivacyPolicy />} />
    <Route path={ t("url.cookies")} element={<CookiesPolicy />} />
    <Route path={ t("url.transparency")} element={<InfoAiutiStato />} />
    <Route path={ t("url.showroom-schedule") } element={<ShowroomSchedulePage url={"https://mailchi.mp/domino-design/landing-page" }  height='720vh'  width='100%' />} />
    <Route path={ t("url.opening-ceremony") }  element={<ShowroomSchedulePage url={"https://mailchi.mp/domino-design/vj3q4nxpi1" } height='830vh'  width='100%'  />} />
    <Route path={ t("url.login")} element={<UserLoginPage />} />
    <Route path={ t("url.registration")} element={<UserRegistrationPage />} />
    <Route path={ t("url.resetpassword")} element={<ResetPasswordPage />} />
    <Route path={ t("url.dashboard") } element={ <PrivateRoute on>  <DashboardHome /> </PrivateRoute> } />
    <Route path={ ("url.accountinfo") } element={<PrivateRoute>  <AccountInfoPage /> </PrivateRoute> } />
    <Route path="*" element={<NoPageFound />} />
    </>
    )
}

export default PublicPaths;