import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ParseHTML from "../utils/parser";
import iconLoad from "../../img/form-loading.gif";


function FormQuoteRequest() {

   const [quoteType, setQuoteType] = useState('Contract');
   const [firstName, setFirstName] = useState('');
   const [firstNameError, setFirstNameError] = useState('');
   const [lastName, setLastName] = useState('');
   const [lastNameError, setLastNameError] = useState('');
   const [email, setEmail] = useState('');
   const [emailError, setEmailError] = useState('');
   const [phone, setPhone] = useState('');
   const [phoneError, setPhoneError] = useState('');
   const [organizationName, setOrganizationName] = useState('');
   const [organizationNameError, setOrganizationNameError] = useState('');
   const [file, setFile] = useState(null);
   const [fileError, setFileError] = useState('');
   const [message, setMessage] = useState('');
   const loadingContainer = document.getElementById('loading-container');
   const buttonSubmit = document.getElementById('submit');


   const handleInputChange = (event, setField, setError, regex) => {
       const inputValue = event.target.value;
       setField(inputValue);

       if (!inputValue) {
         setError('Field is required');
       } else if (!regex.test(inputValue)) {
         setError('Invalid input');
       } else {
         setError('');
       }
     };

  const [t,i18n] = useTranslation("global");
  const LANG = t("lng");
  const navigate = useNavigate();


  const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        if (selectedFile.type !== 'application/pdf' || selectedFile.size > 10 * 1024 * 1024) {
          setFileError('Invalid file format or size');
        } else {
          setFile(selectedFile);
          setFileError('');
        }
      } else {
        setFile(null);
        setFileError('');
      }
    };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (
          firstNameError ||
          lastNameError ||
          emailError ||
          phoneError ||
          organizationNameError ||
          fileError
        ) {
          alert('Please fix the errors before submitting');
          return;
        }

    const sendData = async (data) => {

        const formData = new FormData();

        // Add other JSON data to the form data
        var isContract = true;
        if(quoteType =="Simple"){ isContract = false; }

        formData.append('isContract', isContract );
        formData.append('firstName', data.firstName);
        formData.append('lastName', data.lastName);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('organization', data.organizationName);
        formData.append('message', data.message);

        if (!data.file) {
            formData.append('attachedFile', new Blob([]), '');
        }else{
            formData.append('attachedFile', data.file);
        }


      console.log("inviando la richiesta...")
      loadingContainer.style.display = 'block';
      buttonSubmit.disabled = true;

     try {
       const response = await axios.post(
         "https://www.domino-design.it/domino/api/ui-form/new-quote-request",
         formData,
         {
           headers: { 'Content-Type': 'multipart/form-data' },
         }
       );

      if (response && response.data) { alert("Thank you! We saved your request successfully."); }

     } catch (error) {
       console.log("Error:", JSON.stringify(error));
       console.log("Response:", error.response);
       console.log("Response Data:", error.response && error.response.data);
       alert("An error occurred during the request.");
     }

      console.log("Risposta ricevuta.")
      loadingContainer.style.display = 'none';
      buttonSubmit.disabled = false;
    }

    // Perform desired action with form values
    const formValues = {
      quoteType,
      firstName,
      lastName,
      email,
      phone,
      organizationName,
      file,
      message,
    };

    sendData( formValues );




  };

  return (
    <div className="inner-form">
      <div className="form-group field-quoteType">
        <label htmlFor="quoteType">
          <span className="required">*</span>
           <ParseHTML id={ "quoterequest.form.quote-type-field"} />
        </label>
        <button id="Contract"
            className={quoteType === 'Contract' ? 'selected' : ''}
             onClick={() => setQuoteType('Contract') } > Contract </button>

        <button id="Semplice"
            className={quoteType === 'Semplice' ? 'selected' : ''}
            onClick={() => setQuoteType('Semplice') } > Simple </button>
      </div>

      <div className="form-group field-name">
        <label htmlFor="firstName"> <span className="required">*</span>
            <ParseHTML id={ "quoterequest.form.firstName-field"} />
        </label>
        <input type="text" name="firstName" access="false" id="firstName" required="required"
        aria-required="true" value={firstName}
        onChange={(event) => handleInputChange(event, setFirstName, setFirstNameError, /^[A-Za-z\s]+$/) } />
        {firstNameError && <div className="error-message">{firstNameError}</div>}
      </div>

      <div className="form-group field-name">
        <label htmlFor="lastName"> <span className="required">*</span>
            <ParseHTML id={ "quoterequest.form.lastName-field"} />
        </label>
        <input type="text" name="lastName" access="false" id="lastName" required="required" aria-required="true"
          value={lastName}
          onChange={(event) => handleInputChange(event, setLastName, setLastNameError, /^[A-Za-z\s]+$/) } />
          {lastNameError && <div className="error-message">{lastNameError}</div>}
      </div>

      <div className="form-group field-email">
        <label htmlFor="email"><span className="required">*</span>
         <ParseHTML id={ "quoterequest.form.email-field"} />
        </label>
        <input type="email" name="email" access="false" id="email" required="required" aria-required="true"
          value={email}
          onChange={(event) => handleInputChange(event, setEmail, setEmailError, /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/) } />
          {emailError && <div className="error-message">{emailError}</div>}
      </div>

      <div className="form-group field-tel">
        <label htmlFor="tel"><span className="required">*</span>
            <ParseHTML id={ "quoterequest.form.phone-field"} />
        </label>
        <input type="text" name="tel" access="false" id="tel" required="required" aria-required="true"
          value={phone}
          onChange={(event) => handleInputChange(event, setPhone, setPhoneError, /^[0-9]{10}$/) } />
          {phoneError && <div className="error-message">{phoneError}</div>}
      </div>

      <div className="form-group field-organization">
        <label htmlFor="organizationName"><span className="required">*</span>
            <ParseHTML id={ "quoterequest.form.organization-name-field"} />
        </label>
        <input type="text" name="organizationName" access="false" id="organizationName" required="required" aria-required="true"
        value={organizationName}
        onChange={(event) => handleInputChange(event, setOrganizationName, setOrganizationNameError, /^[^\\/]+$/ ) } />
        {organizationNameError && <div className="error-message">{organizationNameError}</div>}
      </div>

      <div className="form-group field-file">
        <label htmlFor="file">
            <ParseHTML id={ "quoterequest.form.file-field"} /> (PDF, max 10MB)
        </label>
        <input type="file" name="file" accept=".pdf" access="false" multiple="false" aria-required="true"  onChange={handleFileChange} />
      </div>
      {fileError && <div className="error-message" >{fileError}</div>}

      <div className="form-group field-message">
        <label htmlFor="message"><span className="required">*</span>
            <ParseHTML id={ "quoterequest.form.message-field"} />
        </label>
        <textarea type="textarea" name="message" access="false" rows="10"
        id="message" required="required" aria-required="true"
        value={message} onChange={(event) => setMessage(event.target.value)} ></textarea>
      </div>

      <div className="form-group field-submit">
        <button className="btn orange float-r" type="submit" name="submit" access="false" id="submit" onClick={handleSubmit}>
            <ParseHTML id={ "quoterequest.form.submit-button"} />
        </button>
        <img id="loading-container" src={iconLoad} width="40" height="40"/>
      </div>
    </div>
  );
}


export default FormQuoteRequest;

