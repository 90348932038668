import React, { useState, useEffect } from 'react';
import { Link, redirect } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAgentLoginFormContext } from '../../utils/AgentLoginFormContext';
import { useAuth } from "../../utils/AuthProvider";
import FormWindow from "../../utils/FormWindow";
import iconPartner from "../../../img/icon_partner.svg";
import iconClients from "../../../img/icon_clients.svg";
import iconNegotiations from "../../../img/icon_negotiations.svg";
import iconLogout from "../../../img/icon_logout.svg";
import iconAvatar from "../../../img/icon_avatar.svg";
import backendApi from "../../utils/BackendApi";

import { NavLink } from 'react-router-dom';

import AgentLoginComponent from "../../components/AgentLoginComponent"


import "../../../css/private/private-area.css";
function AgentNavBar() {
  const [t,i18n] = useTranslation("global");
  const urlPrefix = ()=> '/'+t("lng")+'/';
  const urlAgentPrefix = () => urlPrefix()+t("agentUrl.home")+"/";
  const urlAgentHomePrefix = () => urlPrefix()+t("agentUrl.home");

  const pathToPublicHome = urlPrefix();
  const navigate = useNavigate();
  const location = useLocation();
  const [nameAgentTopBar, setNameAgentTopBar] = useState('');

  useEffect(() => {
    async function getStoredEmail() {
      try {
        const resp = await backendApi.get("api/agent");
        const agent = resp.data;
        if ( agent.fullName ){
            setNameAgentTopBar( agent.fullName )
            await localStorage.setItem('agentFullName', agent.fullName);
        }
      } catch (error) {
        console.error('Error retrieving email from local storage:', error);
        if( error.response && error.response.status == 403){
            const handleLogout = async () => {
                await logout( );
                const pathToPublicHome = urlPrefix();
                navigate( pathToPublicHome );
            };
            handleLogout();
        }
      }
    }
    getStoredEmail();
  }, []); // Run this effect only once on component mount


  const [scroll, setScroll] = useState(false);
  const { logout } = useAuth();
  const handleLogout = async () => {
        await logout( );
        navigate( pathToPublicHome );
        //navigate perform operation different from Link to. it also ensure that logout has finished. I need both.
  };


  return (
    <header className='private-sidebar'>
      <div className={`${scroll ? "cont-private-sidebar shrink" : "cont-private-sidebar"}`}>
        <nav className="inner-private-sidebar">
          <div className='private-username mb-5'>
            <img src={iconAvatar} width="18" height="18" xmlns="http://www.w3.org/2000/svg" />
            {nameAgentTopBar}
          </div>
          <ul className="private-nav py-4 mb-5">
            <li className="item-nav">
                <NavLink to={ urlAgentPrefix()+t("agentUrl.clients") } className={location.pathname === urlAgentHomePrefix() ?"active":""} ><img src={iconClients}/> {t("agentNavBar.clients")}</NavLink>
            </li>
            <li className="item-nav">
                <NavLink to={ urlAgentPrefix()+t("agentUrl.negotiations") } ><img src={iconNegotiations}/> {t("agentNavBar.negotiations")}</NavLink>
            </li>
          </ul>
          <div className='item-nav'>
            <Link onClick={handleLogout} ><img src={iconLogout}/> { t("agentNavBar.logout")}</Link>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default AgentNavBar;
