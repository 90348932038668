import React, { useState,useEffect } from 'react';
import { Link, useNavigate,useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import { useAuth } from '../utils/AuthProvider';
import EmailConfirmationComponent from "./EmailConfirmationComponent";
import iconLoad from "../../img/form-loading.gif";
import iconClose from "../../img/icon_close.svg";
import iconPartner from "../../img/icon_partner.svg";
import iconMailOutline from "../../img/icon_mail.svg";
import iconPasswordOutline from "../../img/icon_password.svg";
//import GoogleLoginButton from "./GoogleLoginButton";
//import { Helmet } from 'react-helmet-async';

const AgentLoginComponent = ({ onHideForm, onShowForgotPasswordComponent }) => {

    //Check if the user is authenticated
    const { isAuthenticated, logout , login, setPathAfterSuccessfullyLogin, pathToDefaultDashboard } = useAuth();

    const [t,i18n] = useTranslation("global");
    const urlPrefix = () => '/' + t('lng') + '/';
    const navigate = useNavigate();

    //variabili del form
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [responseError, setResponseError] = useState('');


    const handleEmailChange = (event) => { setEmail(event.target.value); };
    const handlePasswordChange = (event) => { setPassword(event.target.value); };

    const loadingLogin = document.getElementById('loading-login');
    const buttonSubmit = document.getElementById('submit');

    //Loading
    const setShowLoadingOnForm = (display=true) => {
      if(display){
        loadingLogin.style.display = 'block';
        buttonSubmit.disabled = true;
      }else{
        loadingLogin.style.display = 'none';
        buttonSubmit.disabled = false;
      }
    }

    const handleLogin = async () => {
 
      setResponseError('');
      setShowLoadingOnForm();

      const resp =  await login( email, password, true );
      setShowLoadingOnForm(false);
      console.log("ricevuta risposta ");
      console.log( resp );
      if( resp.success ){
        const patToRedirect = resp.success;
        onHideForm();
        const randomParam = Math.random();
        navigate( { pathname: patToRedirect, state: { randomParam } } );
      }

      //questa gestione dei messaggi di errore è valida solo per agent login
      if( resp.error == "401" ){
        setResponseError( t("AgentLoginComponent.error.401") );
      }else{
        setResponseError(t("AgentLoginComponent.error.40X") );
      }


    };

    const handleTokenResult = (response) => {
       console.log("Executing token result fun with response value=");
       console.log(response);
       if (response.status == 201){
           handleLogin();
       }
    };


    const handleForgotPassword = () =>{
        onShowForgotPasswordComponent();
    }

    useEffect(() => {
      if( isAuthenticated ){
        //se passo qui e  sono autenticato direttamente ti reindirizzo alla pagina home di agent
        const pathToDefaultDashboard = urlPrefix() + t("agentUrl.home");
        onHideForm();
        navigate( pathToDefaultDashboard );
      }

    }, []);

    return (
        <main class="page-login px-4 py-3">
            <div class="container-form">
                 <img className='icon-close' src={iconClose} onClick={  onHideForm } width="32" height="32"/>
                 <img className='icon-user' src={iconPartner} width="56" height="56"/>
                 <h3 class="form_title t-align-center">{t("AgentLoginComponent.loginTitle")}</h3>
                 <div className="inner-form">
                    <div className="form-group field-name">
                        <img className='icon-field' src={iconMailOutline} width="24" height="24"/>
                        <label htmlFor="email">{t("AgentLoginComponent.email")}</label>
                        <input type="text" placeholder='Email' name="email" id="email" required="required" aria-required="true" value={email} onChange={handleEmailChange} />
                    </div>
                 </div>
                 <div className="inner-form">
                    <div className="form-group field-name">
                      <img className='icon-field' src={iconPasswordOutline} width="24" height="24"/>
                      <label htmlFor="password">{t("AgentLoginComponent.password")}</label>
                      <input type={showPassword ? 'text' : 'password'} placeholder="Password" name="password" id="password" required="required" aria-required="true" value={password} onChange={handlePasswordChange} />
                      <label className="show-password-label">
                      <input type="checkbox" checked={showPassword} onChange={() => setShowPassword(!showPassword)} />
                       {t("AgentLoginComponent.showPassword")}
                      </label>
                    </div>
                 </div>
                 {/*<div className="forgot-password mb-5 p-xsmall">
                    <div className='orange' onClick={ handleForgotPassword }>{t("AgentLoginComponent.forgotpassword")}</div>
                 </div>*/}
                 <button id="submit" className='btn orange' onClick={handleLogin}>{t("AgentLoginComponent.loginButton")}</button>
                 <img id="loading-login" src={iconLoad} width="40" height="40"/>
                 {responseError && <div className="notice error">{responseError}</div>}
           </div>
        </main>
    );
};

export default AgentLoginComponent;
