import Hamburger from 'hamburger-react'
import "../../css/components/navbar.css";
import React, { useState, useEffect  } from 'react';


function ToggleButton() {
  
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {

    if (isOpen) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }, [isOpen]);


  useEffect( () => {
    document.body.classList.remove("menu-open");

    if(isOpen){
        setOpen( !isOpen );
    }

  }, [ window.location.pathname ]);



  return (
    <div>

      <button onClick={ () => { setOpen(!isOpen); } } className={isOpen ? "hamb-menu active" : "hamb-menu"} >
        <Hamburger color="#FFFFFF" toggled={isOpen} toggle={setOpen} />
      </button>

    </div>
  );
}

export default ToggleButton;